import 'summernote/dist/summernote-bs4.min.js'
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Swal from 'sweetalert2';

export default class AccountsManagedFundsEdit {

  constructor() {}

  render() {

	  // Set account id
	  const accountUrl = $('body').attr('data-account-url');

	  // Summernote text editor
	  if ($('.summernote').length !== 0) {
	    $('.summernote').summernote({
	      minHeight: 250,
  			dialogsInBody: true,
	      toolbar: [
	        ['font', ['bold', 'italic', 'underline']],
    			['para', ['ul', 'ol', 'paragraph']],
	        ['insert', ['picture', 'link', 'video']],
	      ],
			  callbacks: {
			    onDialogShown: function() {
			      $('.modal-backdrop')[1].remove();
			    }
			  }
			});
	  }

	  // Draggable sections
	  $('#sortableSections').sortable({
	    placeholder: "available-section-space",
	    update: function(event, ui) {
	      let sortOrder = $(this).sortable('toArray');
	      $.each(sortOrder, function(ind, sectionId) {
	        $('#' + sectionId).find('.hidden-sort-order').val(ind + 1);
	      });
	    }
	  });

	  $('#sortableSections').on("sortstart", function(event, ui) {
	  	$('.available-section-space, .section-option').addClass('sorting');
	  });
	  $('#sortableSections').on("sortstop", function(event, ui) {
	  	$('.available-section-space, .section-option').removeClass('sorting');
	  });

	  // Disable sortable on expand of sections; otherwise input doesn't work
	  $('.collapse-item').click(function() {
	  	let opened = $(this).closest('.card').hasClass('minimized');
	  	if (opened === false) {
	  		$('#sortableSections').sortable( "disable" );
	  	} else {
	  		$('#sortableSections').sortable( "enable" );
	  		let title = $(this).closest('.card').find('.title-input-val').val();
	  		$(this).closest('.card').find('.tab-section-title').empty().append(title);
	  	}
	  })

	  // Select layout; re-load with selected layout
	  $('.layout-opt').click(function() {
	  	$('.modal-body').css('opacity', 0.2);
	  	Spinner($('.modal-content'));
	  	let opt = $(this).attr('data-opt');
	  	$('#layout_preview_input').val(opt);
	  	$(this).closest('form').submit();
	  })

	  // Preview
	  $('.preview-tab').click(function() {
	  	$('.modal-body').css('opacity', 0.2);
	  	Spinner($('.modal-content'));
	  	let form = $(this).closest('form');
	  	form.append("<input type='hidden' value= 'true' name='show_preview'>");
	  	form.submit();
	  })

	  // Fix for codeview
	  $('.btn-codeview').click(function() {
	  	let active = $(this).hasClass('active');
	  	if (active) {
	  		$('.note-editable').css('height', "auto");
	  	} else {
	  		$('.note-editable').css('height', "calc(100vh - 42px)");
	  	}
	  })

	  // Add a section
	  $('.add-section').click(function() {
	    $('.modal-body').css('opacity', 0.2);
	    Spinner($('.modal-content'));
      let fundId = $(this).attr('data-fund-id');
      let url = '/' + accountUrl + '/managed_funds/' + fundId + '/create_section';
      return $.ajax({
        type: 'POST',
        url: url,
        dataType: "application/json",
        complete(result) { 
          let url = window.location.href;
          return Turbo.visit(url);
        },
      });
	  })

	  // Remove a section
	  $('.remove-section').click(function() {
	    $('.modal-body').css('opacity', 0.2);
	    Spinner($('.modal-content'));
      let fundId = $(this).attr('data-fund-id');
      let sectionId = $(this).attr('data-section-id');
      let url = '/' + accountUrl + '/managed_funds/' + fundId + '/remove_section/' + sectionId;
	    return Swal.fire({
	      title: "Are you sure?",
	      text: "Are you sure you want to remove this section? You will lose the content for this section and this cannot be undone.",
	      animation: false,
	      focusConfirm: false,
	      showCancelButton: true,
	      confirmButtonText: 'Remove section',
	      cancelButtonText: 'Cancel',
	      customClass: {
	        confirmButton: 'btn btn-primary',
	        cancelButton: 'btn btn-light border',
	        popup: 'animated fadeIn faster'
	      }
	    }).then((result) => {
	      if (result.value) {
		      $.ajax({
		        type: 'DELETE',
		        url: url,
		        dataType: "application/json",
		        complete(result) { 
		          let url = window.location.href;
		          return Turbo.visit(url);
		        },
		      });
	      }
	    });
	  })
 
  }

}