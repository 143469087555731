import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import SetAxisMin from 'src/javascripts/components/charts/axes/SetAxisMin';
import SetAxisMax from 'src/javascripts/components/charts/axes/SetAxisMax';
import SetAxisTicks from 'src/javascripts/components/charts/axes/SetAxisTicks';
import SetChartTotal from 'src/javascripts/components/charts/actions/SetChartTotal';
import ChangeHistoricalChartDate from 'src/javascripts/components/charts/actions/ChangeHistoricalChartDate';
import DefaultChartTooltip from 'src/javascripts/components/charts/tooltips/DefaultChartTooltip';

export default class AdminHistoricalChart extends Chart {

  getData() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url
    let url = this.el.attr('data-api-url');
    let params = {};
    let fromDate = this.el.attr('data-api-from-date');
    let accountType = this.el.attr('data-account-type');
    let paymentStatus = this.el.attr('data-payment-status');
    let country = this.el.attr('data-country');
    if (typeof fromDate !== 'undefined') {params['from_date'] = fromDate}
    if (typeof accountType !== 'undefined') {params['account_type'] = accountType}
    if (typeof paymentStatus !== 'undefined') {params['payment_status'] = paymentStatus}
    if (typeof country !== 'undefined') {params['country'] = country}

    // Get data
    return $.getJSON(url + "?" + $.param(params));
  }

  render(data) {

    let chartId = this.el.attr('id')

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {left: 0, bottom: 0, top: 0},
      data: {
        x: 'date',
        columns: data,
        type: 'line'
      },
      point: {show: false},
      axis: {
        x: {
          show: false,
          type: 'timeseries',
          padding: {left: 0, right: 0}
        },
        y: {
          show: false,
          padding: {bottom: 0, top: 0}
        }
      },
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return DefaultChartTooltip(chart, this, d, defaultTitleFormat, defaultValueFormat, 'decimals', 2, color);
        }
      },
      grid: {
        y: {show: false},
        x: {show: false}
      }
    });

    return chart;
  }

  setUp(chart) {

    // Return if no chart available (don't attempt to set up below)
    if (typeof chart === 'undefined') {return;}

    // Set name and total at top of chart
    SetChartTotal(chart, chart.data()[0].id);

    // On click of an 'update-chart-date' button (change date of data on chart)
    $(chart.element).closest('.chart-section').find('.update-chart-date').click(function() {
      let $fromDate = $(this).attr('data-from-date');
      return ChangeHistoricalChartDate(chart, $fromDate);
    });

    // Set chart data to allow for accessing chart programmatically (used to allow tables to add/remove data)
    // Also allows for destroying chart before Turbo cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    let chartId = '#' + $(chart.element).attr('id');
    $(chartId).data('c3-chart', chart);

    // Remove spinner
    $(chart.element).find('.spinner-container').remove();

    return chart;
  }

};
