import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';

export default class QuestionnairesCollectionDocumentsIndex {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Add border to navbar on scroll
    NavQuestionnaire();

    // Render table
    if ( $('#questionnaire_response_documents_table_wrapper').length === 0 ) {
      let table = new DefaultTable($('#questionnaire_response_documents_table'));
      $.when( table.render() ).then( status => table.setUp('questionnaire_response_documents_table') )
    }

    // Upload file
    // Set environment and account id for AWS path vars
    $('.response-document-filepicker').each(function(i, elem) {
      let fileInput     = $(elem);
      let filePicker    = $(this);
      let env           = fileInput.data("env");
      let primaryColor  = fileInput.data("primary-color");
      var progressBar   = $("<div class='bar'></div>");
      var barContainer  = $("<div class='progress'></div>").append(progressBar);
      $('#fileUploadProgress').append(barContainer);
      fileInput.fileupload({
        fileInput:        fileInput,
        url:              fileInput.data('url'),
        type:             'POST',
        autoUpload:       true,
        formData:         fileInput.data('form-data'),
        paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
        dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
        replaceFileInput: false,
        progressall: function (e, data) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.css('width', progress + '%')
        },
        start: function (e) {
          progressBar.
            css('background', primaryColor).
            css('display', 'block').
            css('width', '0%').
            css("height", "25px");
        },
        done: function(e, data) {
          progressBar.text("");

          // extract key from response
          let key = $(data.jqXHR.responseXML).find("Key").text();
          let responseId = filePicker.attr('data-response-id');
          let fileType = key.substr( (key.lastIndexOf('.') +1) );
          
          // Only continue if allowed
          let allowed = ["pdf", "PDF", "pptx", "docx", "doc", "xlsx", "csv", "png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "webp", "WEBP", "txt", "MP4", "mp4", "zip"]
          if (allowed.indexOf(fileType) <= -1) {
            progressBar.
              css('display', 'none').
              css('width', '0%').
              css("height", "0px");
            return ToastCustom('Please use an accepted file type', "Please upload a file with an allowed file type. You may view allowed file types from the link in the instructions on this page.", 15000);

          } else {

            // Set spinner so user knows in progress
            $(this).closest('.filepicker-container .filepicker-label').addClass('opaque');
            Spinner($(this).closest('.filepicker-container'));

            // Upload file
            let url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/documents.json';
            let formData = {}
            formData['file'] = {}
            formData['file']['key'] = key;

            // Update local attribute
            return $.ajax({
              type: 'POST',
              url: url,
              dataType: "application/json",
              data: formData,
              complete(result) { 
                let url;
                let responseId = filePicker.attr('data-response-id');
                let moduleId = filePicker.attr('data-module-id');
                let params = {};

                // Set open elements
                let openEls = [];
                $('div[aria-expanded=\'true\']').each(function() {
                  let controls = $(this).attr('aria-controls');
                  openEls.push(controls);
                });
                params['openElements'] = openEls;

                if (result.status === 200) {
                  params['notice'] = 'Successfully added document';
                  url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/documents' + '?' + $.param(params);
                } else {
                  params['notice'] = 'There was a problem, please contact us for support';
                  url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/documents' + '?' + $.param(params);
                }
                return Turbo.visit(url);
              },
            });
          }

        },
        fail: function(e, data) {
          progressBar.
            css("background", "red").
            text("Failed");
        }

      });

    });


  }

}