import Dashboards from 'src/javascripts/components/Dashboards';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class AccountsShow {

  constructor() {}

  render() {

    // Set account id
    const accountUrl = $('body').attr('data-account-url');

    // If in PE
    if (typeof $('#dashboardPortfolio') !== 'undefined') {
      $(window).scroll(function() {
        if ($(window).scrollTop() > 30) {
          return $('.sticky-header').addClass('border-bottom-dark');
        } else {
          return $('.sticky-header').removeClass('border-bottom-dark');
        }
      });

      // Dashboards
      Dashboards();

      // Selected portfolio for dashboard
      $('#dashboardPortfolio').change(function() {
        let val = $(this).val();
        let url = window.location.pathname;
        url = url + '?portfolio_id=' + val;
        Turbo.visit(url);
      });

    }

    $(document).on("turbo:before-cache", function() { 

      // Hide navbar dropdowns, otherwise reappears 
      // for a second when Turbo reloads the page
      $('.top-nav-dropdown').removeClass('show');

    });

    // Auto-focus on expanding dropdown
    $('.dropdown').on('shown.bs.dropdown', function() {
      $(this).find('.typeahead').focus();
    });



  }
}