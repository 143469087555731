// Stripe main subscribe methods (for initial create customer and subscription)
// https://github.com/stripe-samples/set-up-subscriptions/blob/master/client/script.js#L90-L117
import SuccessfulUpdateToast from 'src/javascripts/components/stripe/SuccessfulUpdateToast';
import ChangeLoadingState from 'src/javascripts/components/utilities/ChangeLoadingState';

export default function() {

  // Set constants
  const accountUrl = $('body').attr('data-account-url');
  const productId = $('#submitSubscriptionForm').attr('data-product-id');
  const priceId = $('#submitSubscriptionForm').attr('data-price-id');

  // Show success toast if params indicate success
  if ( typeof $('#subscriptionForm').attr('data-subscription-updated') !== 'undefined') {
    SuccessfulUpdateToast();
  };

  // Show a success / error message when the payment is complete
  // Reload page with Turbo
  var orderComplete = function(subscription) {
    ChangeLoadingState($('#submitSubscriptionForm'), false);

    // Send welcome email
    let url = '/marketplace/' + accountUrl + '/subscriptions/welcome_new_account';
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url,
    });

    // Reload page
    Turbo.visit("/marketplace/products/" + productId + "?m=s")
  }

  // If the Stripe card form is present -- i.e., if 
  // the user has not yet subscribed
  if ($('#newSubscriptionCard').length !== 0) {
    var stripe;

    // Initiate StripeElements
    var stripeElements = function(publicKey) {
      stripe = Stripe(publicKey);
      var elements = stripe.elements();

      // Set basic style of input form to match app
      var style = {
        base: {
          fontSize: '16px',
          color: '#6c757d',
          fontFamily: 'Source Sans Pro, sans-serif',
          '::placeholder': {
            color: '#adb5bd'
          }
        }
      };

      // Create card element and listen for change as user types
      // Display error messages below form 
      var card = elements.create('card', { style: style });
      card.mount('#newSubscriptionCard');

      // Element focus ring
      card.on('focus', function() {
        var el = document.getElementById('newSubscriptionCard');
        el.classList.add('focused');
      });
      card.on('blur', function() {
        var el = document.getElementById('newSubscriptionCard');
        el.classList.remove('focused');
      });

      // Show errors as user types in form
      card.addEventListener('change', ({error}) => {
        const displayError = document.getElementById('newSubscriptionCardErrors');
        if (error) {
          displayError.className = 'with-error';
          displayError.textContent = error.message;
        } else {
          displayError.className = '';
          displayError.textContent = '';
        }
      });

      // Listen for submit
      if ($('#submitSubscriptionForm').length !== 0) {
        document.querySelector('#submitSubscriptionForm').addEventListener('click', function(evt) {
          evt.preventDefault();
          // Change load state
          ChangeLoadingState($('#submitSubscriptionForm'), true);
          // Create payment method and customer
          createPaymentMethodAndCustomer(stripe, card);
        });
      }
    };

    // Get public key from Stripe and initiate the Stripe Elements container
    function getPublicKey() {
      return fetch('/marketplace/' + accountUrl + '/stripe-public-key', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          stripeElements(response.publicKey);
        });
    }

    getPublicKey();

  } else if ($('#submitSubscriptionForm').length !== 0) {

    // Listen for updating the subscription plan
    // Indicates user has already created a subscription, and this is just changing it    
    document.querySelector('#submitSubscriptionForm').addEventListener('click', function(evt) {
      evt.preventDefault();
      // Change load state
      ChangeLoadingState($('#submitSubscriptionForm'), true);
      // Update existing subscription
      updateSubscription();
    });

  }

  // Show error if card declined
  function showCardError(error) {
    ChangeLoadingState($('#submitSubscriptionForm'), false);
    // The card was declined (i.e. insufficient funds, card has expired, etc)
    var errorMsg = document.querySelector('#newSubscriptionCardErrors');
    errorMsg.textContent = error.message;
    setTimeout(function() {
      errorMsg.className = '';
      errorMsg.textContent = '';
    }, 8000);
  }

  // Create a payment method and customer
  var createPaymentMethodAndCustomer = function(stripe, card) {
    var cardholderEmail = document.querySelector('#email').value;
    stripe
      .createPaymentMethod('card', card, {
        billing_details: {
          email: cardholderEmail
        }
      })
      .then(function(result) {
        if (result.error) {
          showCardError(result.error);
        } else {
          createCustomer(result.paymentMethod.id, cardholderEmail);
        }
      });
  };

  // Create customer and subscription with async call, return subscription
  async function createCustomer(paymentMethod, cardholderEmail) {
    var backdateStartDate = document.querySelector('#backdate_start_date').value;
    return fetch('/marketplace/' + accountUrl + '/stripe/customers', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: cardholderEmail,
        payment_method: paymentMethod,
        product_id: productId,
        price_id: priceId,
        backdate_start_date: backdateStartDate
      })
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.subscription !== null) {
          handleSubscription(response.subscription);
        }
      });
  }

  // Update selected subscription with async call, return subscription
  var updateSubscription = function() {
    var prorationDate = document.querySelector('#backdate_start_date').value;
    return fetch('/marketplace/' + accountUrl + '/enabled_products', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        product_id: productId,
        price_id: priceId,
        proration_date: prorationDate
      })
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        console.log(response);
        if (response.subscription !== null) {
          handleSubscription(response.subscription);
        }
      });
  }

  // Check subscription object returned from Stripe 
  // If there are issues we need to handle them with customer now
  function handleSubscription(subscription) {

    // Handle error if any
    if (typeof subscription.error !== 'undefined') {
      return showCardError(subscription);
    } 

    // Otherwise continue
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {
      const { client_secret, status } = payment_intent;

      if (status === 'requires_action' || status === 'requires_payment_method') {
        stripe.confirmCardPayment(client_secret).then(function(result) {
          if (result.error) {
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            ChangeLoadingState($('#submitSubscriptionForm'), false);
            showCardError(result.error);
          } else {
            // Show a success message to your customer
            orderComplete(subscription);
          }
        });
      } else {
        // No additional information was needed
        // Show a success message to your customer
        orderComplete(subscription);
      }
    } else {
      orderComplete(subscription);
    }
  }

}