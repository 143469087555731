import ApiRequestsChart from 'src/javascripts/components/charts/ApiRequestsChart';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';

export default class AccountsResearchApiLogs {

  constructor() {}

  render() {

    // Render API requests chart, if chart does not already have c3-data (already initialized)
    // Needed to prevent re-init when navigating away from page, before new body has loaded    
    if (typeof $('#api_requests_chart').data('c3-chart') === 'undefined') {
      let chart = new ApiRequestsChart( $('#api_requests_chart') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );
    }

    // API requests table
    if ($("#api_requests_table_wrapper").length === 0) {
      let table = new DefaultTable($('#api_requests_table'));
      $.when( table.render() ).then( status => table.setUp('api_requests_table') )
    }

    // Reload with from/to date on filter
    $('#from_date').change(function() {
      let accountUrl = $('body').attr('data-account-url');
      let params = {};
      params['from_date'] = $(this).val();
      params['to_date'] = $('#to_date').val();
      let url = "/" + accountUrl + "/api/logs?" + $.param(params);
      Turbo.visit(url);
    });

    // Reload with from/to date on filter
    $('#to_date').change(function() {
      let accountUrl = $('body').attr('data-account-url');
      let params = {};
      params['to_date'] = $(this).val();
      params['from_date'] = $('#from_date').val();
      let url = "/" + accountUrl + "/api/logs?" + $.param(params);
      Turbo.visit(url);
    });

  }

}