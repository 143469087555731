import Spinner from 'src/javascripts/components/utilities/Spinner';
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';

export default class SettingsAccountsMethodologiesImportsNew {

  constructor() {}

  render() {

    // Upload file
    // Set environment and account id for AWS path vars
    $('.methodology-filepicker').each(function(i, elem) {
      let fileInput     = $(elem);
      let env           = fileInput.data("env");
      let accountUrl       = fileInput.data("account-url");
      let scope         = fileInput.data("scope");
      var progressBar   = $("<div class='bar'></div>");
      var barContainer  = $("<div class='progress'></div>").append(progressBar);
      $('#fileUploadProgress').append(barContainer);
      fileInput.fileupload({
        fileInput:        fileInput,
        url:              fileInput.data('url'),
        type:             'POST',
        autoUpload:       true,
        formData:         fileInput.data('form-data'),
        paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
        dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
        replaceFileInput: false,
        progressall: function (e, data) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.css('width', progress + '%')
        },
        start: function (e) {
          progressBar.
            css('background', '#8c81d1').
            css('display', 'block').
            css('width', '0%').
            text("Loading...");
        },
        done: function(e, data) {
          progressBar.text("Uploading done");

          // extract key from methodology
          let key = $(data.jqXHR.responseXML).find("Key").text();
          let accountUrl = $('body').attr('data-account-url');
          let methodologyId = $('.methodology-filepicker').attr('data-methodology-id');
          let params = {};

          // Set spinner so user knows in progress
          $('.filepicker-container .filepicker-label').addClass('opaque');
          Spinner($('.filepicker-container'));

          // Check whether uploaded file is csv or xls
          if (key.slice(-4) !== '.csv') {
            let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/imports/new?';
            Turbo.visit(url + 'notice=Only .csv files allowed');

          } else {
            let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/imports?' + $.param(params);
            let formData = {}
            formData['file'] = {}
            formData['file']['key'] = key;

            // Update local attribute
            return $.ajax({
              type: 'POST',
              url: url,
              dataType: "application/json",
              data: formData,
              complete(result) { 
                let url;
                let accountUrl = $('.methodology-filepicker').attr('data-account-url');
                let methodologyId = $('.methodology-filepicker').attr('data-methodology-id');
                let causeId = $('.methodology-filepicker').attr('data-cause-id');
                let params = {};
                if (typeof causeId !== 'undefined') {params['cause_id'] = causeId}
                if (scope === 'companies') {
                  if (result.status === 200) {
                    params['notice'] = 'We are in the process of importing your universe. This usually takes less than 1 minute.';
                    url = '/' + accountUrl + '/methodologies/' + methodologyId + '/companies?' + $.param(params);
                  } else {
                    params['notice'] = 'There was a problem, please contact us for support';
                    url = '/' + accountUrl + '/methodologies/' + methodologyId + '/companies?' + $.param(params);
                  }
                } else if (scope === 'sectors') {
                  if (result.status === 200) {
                    params['notice'] = 'We are in the process of importing your classifications. This usually takes less than 1 minute.';
                    url = '/' + accountUrl + '/methodologies/' + methodologyId + '/sectors?' + $.param(params);
                  } else {
                    params['notice'] = 'There was a problem, please contact us for support';
                    url = '/' + accountUrl + '/methodologies/' + methodologyId + '/sectors?' + $.param(params);
                  }
                } else {
                  if (result.status === 200) {
                    params['notice'] = 'We are in the process of importing your weights. This usually takes less than 1 minute.';
                    url = '/' + accountUrl + '/methodologies/' + methodologyId + '/edit?' + $.param(params);
                  } else {
                    params['notice'] = 'There was a problem, please contact us for support';
                    url = '/' + accountUrl + '/methodologies/' + methodologyId + '/edit?' + $.param(params);
                  }
                }
                return Turbo.visit(url);
              },
            });

          }

        },
        fail: function(e, data) {
          progressBar.
            css("background", "red").
            text("Failed");
        }
      });
    });

  }

}