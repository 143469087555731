import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class ClientsTypeahead {

  constructor(el) {this.el = el;}

  initializeBloodhound() {

    const accountUrl = $('body').attr('data-account-url');
    let apiUrl = $(this.el).attr('data-api-url');
    if (typeof apiUrl === 'undefined') {
      apiUrl = '/' + accountUrl + '/clients/search'
    }
    
    const bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: apiUrl + '?query=%QUERY',
        wildcard: "%QUERY",
        cache: false
      }});

    bloodhound.initialize();
    return bloodhound;

  }

  render(bloodhound, autocomplete = 'off') {

    let emptyTemplate = '<div class="empty-message">Unable to find that</div>';

    let extended;
    if ($(this.el).hasClass('extended')) {
      extended = 'true';
    } else {
      extended = 'false';
    }

    let clientTemplate = (name) => 
      '<div>' + name + '</div>';

    $(this.el).typeahead({
      hint: true,
      highlight: true,
      minLength: 0
    }, {
      name: 'typeahead',
      display: 'name',
      limit: 204,
      source: bloodhound.ttAdapter(),
      templates: {
        empty: emptyTemplate,
        suggestion(el) {
          if (typeof el.name === 'undefined') {
            return emptyTemplate;
          } else if (extended === 'true') {
            return clientTemplate(el.name_extended);
          } else {
            return clientTemplate(el.name);            
          }
        }
      }
    });
  }

  setUp() {

    // Auto-focus typeahead
    $('#clientsFilterDropdown').on('shown.bs.dropdown', function () {
      $('#clients_typeahead').focus();
    })    

    $('#clients_typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 
      let url = $(this).attr('data-url');
      let params = {}
      let portfolioType = $(this).attr('data-portfolio-type');
      let reportType = $(this).attr('data-report-type');
      let accountId = $(this).attr('data-account-id');
      let userId = $(this).attr('data-user-id');
      let filt = suggestion.value;
      if (typeof portfolioType !== 'undefined') {params['portfolio_type'] = portfolioType};
      if (typeof reportType !== 'undefined') {params['report_type'] = reportType};
      if (typeof accountId !== 'undefined') {params['account_id'] = accountId};
      if (typeof userId !== 'undefined') {params['user_id'] = userId};
      params['client_id'] = filt;
      Turbo.visit(url + '?' + $.param(params));
    })
  }

}
