import Table from 'src/javascripts/components/Table';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';

export default class ReportFundStoriesTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: dataUrl,
      paging: false,
      searching: true,
      order: [[0, 'asc']],
      dom: 't',
      columns: cols,
      responsive: { details: false },
      language: { 
        lengthMenu: "Show _MENU_ stories",
        infoEmpty: "No stories found. Available stories are based on funds in the portfolio.",
        emptyTable: "No stories found. Available stories are based on funds in the portfolio.",
        zeroRecords: "No stories found. Available stories are based on funds in the portfolio."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);
        if (typeof data.included_raw !== 'undefined') { $(row).attr("data-included", data.included_raw); }

        // Add hover button links to rows, if passed in data
        if (typeof data.hover_links !== 'undefined') { AddRowHoverButtons(row, data.hover_links); }
      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();        

        // Clear if no available stories
        let json = settings.json;
        if (typeof json !== 'undefined') {        
          if (json.recordsTotal === 0) {
            $('#reportChooseFundStories').empty().append("<div class=\'mb-3\'>No stories available for your selected portfolio. Stories are sourced from fund managers and made available based on fund holdings in the portfolio.</div><div>If there's a fund story you would like to see included on Ethos, please reach out to us.</div>")
          }
        }

        // Confirm and delete an item on click
        $('.add-fund-story').click(function(e) {

          // Spinner
          $('#reportChooseFundStories').css('opacity', 0.25)
          Spinner($('#reportChooseFundStories'));

          // Set vars
          let accountUrl = $('body').attr('data-account-url');
          let reportId = $('#' + tableId).attr('data-report-id');
          let fundStoryId = $(this).closest('tr').attr('data-record-id');
          let url = '/' + accountUrl + '/reports/' + reportId + '/add_fund_story/' + fundStoryId;

          // Add the fund story
          $.ajax({
            type: 'POST',
            url: url,
            dataType: "application/json",
            complete(result) { 
              $('#' + tableId).DataTable().ajax.reload(); 
              $('#reportChooseFundStories').css('opacity', 1)
              $('#reportChooseFundStories').find('.spinner-container').remove();
              ToastCustom('Ethos', 'Added fund story to report', 3000);
            }
          });

        });

        // Confirm and delete an item on click
        $('.remove-fund-story').click(function(e) {

          // Spinner
          $('#reportChooseFundStories').css('opacity', 0.25)
          Spinner($('#reportChooseFundStories'));

          // Set vars
          let accountUrl = $('body').attr('data-account-url');
          let reportId = $('#' + tableId).attr('data-report-id');
          let fundStoryId = $(this).closest('tr').attr('data-record-id');
          let url = '/' + accountUrl + '/reports/' + reportId + '/remove_fund_story/' + fundStoryId;

          // Remove the fund story
          $.ajax({
            type: 'DELETE',
            url: url,
            dataType: "application/json",
            complete(result) { 
              $('#' + tableId).DataTable().ajax.reload(); 
              $('#reportChooseFundStories').css('opacity', 1)
              $('#reportChooseFundStories').find('.spinner-container').remove();
              ToastCustom('Ethos', 'Removed fund story from report', 3000);
            }
          });

        });
      }
    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Add/remove on click of row
    let allTds = 'tbody > tr > td:not(last-child)';
    $('#' + tableId).on('click', allTds, function() { 

      // Spinner
      $('#reportChooseFundStories').css('opacity', 0.25)
      Spinner($('#reportChooseFundStories'));

      // Set vars
      let accountUrl = $('body').attr('data-account-url');
      let reportId = $('#' + tableId).attr('data-report-id');
      let fundStoryId = $(this).closest('tr').attr('data-record-id');
      let includedRaw = $(this).closest('tr').attr('data-included');

      // Update
      if (includedRaw === "true") {

        // Remove the fund story
        let url = '/' + accountUrl + '/reports/' + reportId + '/remove_fund_story/' + fundStoryId;
        $.ajax({
          type: 'DELETE',
          url: url,
          dataType: "application/json",
          complete(result) { 
            $('#' + tableId).DataTable().ajax.reload(); 
            $('#reportChooseFundStories').css('opacity', 1)
            $('#reportChooseFundStories').find('.spinner-container').remove();
            ToastCustom('Ethos', 'Removed fund story from report', 3000);
          }
        });

      } else {

        // Add the fund story
        let url = '/' + accountUrl + '/reports/' + reportId + '/add_fund_story/' + fundStoryId;
        $.ajax({
          type: 'POST',
          url: url,
          dataType: "application/json",
          complete(result) { 
            $('#' + tableId).DataTable().ajax.reload(); 
            $('#reportChooseFundStories').css('opacity', 1)
            $('#reportChooseFundStories').find('.spinner-container').remove();
            ToastCustom('Ethos', 'Added fund story to report', 3000);
          }
        });

      }
    });

    // Remove spinner
    $('#reportChooseFundStories').find('.spinner-container').remove();

    return 'setup';

  }


}
