import Spinner from 'src/javascripts/components/utilities/Spinner';
import Dashboards from 'src/javascripts/components/Dashboards';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import ClimateHazardsChart from 'src/javascripts/components/charts/ClimateHazardsChart';
import ManualListTypeahead from 'src/javascripts/components/typeaheads/ManualListTypeahead';
import ClimateRiskTimeseriesChart from 'src/javascripts/components/charts/ClimateRiskTimeseriesChart';

export default class PortfoliosClimateRiskIndex {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Dashboards
    Dashboards();

    // Scroll to anchor if present
    let anchor = $('#profileTabContent').attr('data-anchor');
    if (typeof anchor !== 'undefined') {
      let toExpand = $('#' + anchor).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(anchor);
      }, 500);
    }

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let modelId = isGenerating.attr('data-model-id');
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['scope'] = 'portfolio';
        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/check_import_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message').length !== 0) { importPoll(); }

    // Render climate hazards chart if not already loaded
    if ( ($('#climate_hazards_chart').length !== 0) ) {

      let chart = new ClimateHazardsChart( $('#climate_hazards_chart') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );

    };

    if (($('#portfolio_locations_table').length > 0) && ($('#portfolio_locations_table tbody td').length === 0)) {
      let tableId = 'portfolio_locations_table';
      let portfolioLocationsTable = new DefaultTable($('#' + tableId));
      $.when( portfolioLocationsTable.render(tableId) ).then( status => portfolioLocationsTable.setUp(tableId) );
    }

    // If historical climate risk
    if ($('#climate_var_over_time_chart').length > 0) {

      // Return if already has c3-data (already initialized)
      // Needed to prevent re-init when navigating away from page, before new body has loaded
      if (typeof $('#climate_var_over_time_chart').data('c3-chart') === 'undefined') { 

        let chart = new ClimateRiskTimeseriesChart($('#climate_var_over_time_chart'));

        // Get data, render chart, set up chart, and create connected table, if exists
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      }
    };


    // Manual list typeaheads
    if ($('.manual-list-typeahead').length !== 0) {
      $('.manual-list-typeahead').each(function(el){
        let id = $(this).attr('id');
        let t = new ManualListTypeahead(el);
        t.render(id)
      });

      // On selecting a fund as benchmark
      $('.clear-typeahead').click(function() {
        let t = $(this).closest('.form-group').find('.typeahead');
        t.typeahead('val', '');
        t.focus();
      })

      $('.manual-list-typeahead').bind('typeahead:beforeselect', function(ev, suggestion) {

        // Set spinner and opacity
        $('.page-wrapper').css('opacity', 0.2);
        Spinner($('.page-wrapper'));

        // Set params
        let url = window.location.pathname;
        let params = {};
        let p = $(this).attr('data-param');
        params[p] = suggestion;
        url = url + '?' + $.param(params);

        // Clear Turbo cache to avoid showing a screen without modal loaded
        Turbo.clearCache(); 

        // Reload page with Turbo
        Turbo.visit(url);

      });

    }

  }

}