import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

// Pick an image and update an associated attribute in DB
export default function(accepted_file_types = null) {

  // Set environment and account id for AWS path vars
  $('.filepicker').each(function(i, elem) {
    let fileInput    	= $(elem);
	  let env 					= fileInput.data("env");
	  let accountUrl 			= fileInput.data("account-url");
    let firmId  = fileInput.data("firm-id");
    let firmUrl = fileInput.data("firm-url");
    let recordId      = fileInput.data("record-id");
    let scope         = fileInput.data("scope");
    let backPath      = fileInput.data("back-path");
    var progressBar  	= $("<div class='bar'></div>");
    var barContainer 	= $("<div class='progress'></div>").append(progressBar);
    $('#imageUploadProgress').append(barContainer);
    fileInput.fileupload({
      fileInput:       	fileInput,
      url:             	fileInput.data('url'),
      type:            	'POST',
      autoUpload:       true,
      formData:         fileInput.data('form-data'),
      paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%')
      },
      start: function (e) {
        progressBar.
          css('background', '#8c81d1').
          css('display', 'block').
          css('width', '0%').
          text("Loading...");
      },
      done: function(e, data) {
        progressBar.text("Uploading done");

        $('.modal-body').css('opacity', 0.3);
        Spinner($('.modal-content'));

        // extract key from response
        let key = $(data.jqXHR.responseXML).find("Key").text();
        let ext = key.split(".").pop().toLowerCase();
        if (typeof accepted_file_types !== 'undefined') {
          let inArr = jQuery.inArray(ext, accepted_file_types);
          if (inArr === -1) {
            ToastCustom('File type not allowed', 'Please select from the allowed file types', 5000);
            $('.modal-body').css('opacity', 1);
            $('.modal-content').find('.spinner-container').remove();
            progressBar.css('width', '0%')
            progressBar.
              css('background', 'transparent').
              css('display', 'none').
              css('width', '0%').
              text("");
            return;
          }
        }

        // Create image in local DB
        let updateUrl;
        let formData;
        if (typeof firmUrl !== 'undefined') {
  			  updateUrl = '/firm/' + firmUrl + '/images';
  	      formData = {'image': {'url': key, 'admin': true, 'firm_id': firmId, 'image_type': scope}};
        } else {
          updateUrl = '/' + accountUrl + '/images';
          formData = {'account_image': {'url': key, 'image_type': scope}};
        }

	      // Update local attribute
        return $.ajax({
	        type: 'POST',
	        url: updateUrl,
	        dataType: "application/json",
	        data: formData,
	        complete(result) { 
            $('.modal-content').find('.spinner-container').remove();
	          let url = window.location.href.split('?')[0];
            url += "?back_path=" + backPath;
            if (typeof recordId !== 'undefined') {url += "&record_id=" + recordId}
            if (typeof scope !== 'undefined') {url += "&update_scope=" + scope}
	          return Turbo.visit(url);
	        },
	      });

      },
      fail: function(e, data) {
        progressBar.
          css("background", "red").
          text("Failed");
      }
    });
  });

}