import * as c3 from 'c3';
import * as d3 from 'd3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import DefaultChartTooltip from 'src/javascripts/components/charts/tooltips/DefaultChartTooltip';

export default class AssessmentCausesChart extends Chart {

  render(data) {

    // Set a spinner to indicate chart loading
    Spinner('assessment_causes_chart');

    // Set data and labels
    let pData = JSON.parse($('#assessment_causes_chart').attr('data'));
    let columns = [];
    $.each(pData, function(i, o) {
      columns.push([o['name'], o['weight']]);
    });

    // Set colors key
    let colorsKey = {
      "Inclusive economies": "#f2d4b5",
      "Life on earth": "#dbe4be",
      "Peace and justice": "#D5E174", 
      "Quality education": "#ffe6b3", 
      "Innovation": "#ffc6b3", 
      "Climate action": "#bfe5c7", 
      "Gender equality": "#d1d1e0", 
      "Sustainable resource use": "#d1e0e0", 
      "Water and sanitation": "#B6D0F2", 
      "Health and well-being": "#bef4f4",
      "Faith-based values": "#C3E5ED", 
    };

    // Render chart
    const chart = c3.generate({
      bindto: '#assessment_causes_chart',
      padding: {
        top: 0,
        bottom: -20,
        left: 0,
        right: 0
      },
      data: {
        type: 'pie',
        columns: columns,
        onclick: function (d, i) { 
          console.log("onclick", d, i); 
        },
        onmouseover: function (d, i) { 
          let selected = $('.pie-chart-label[data-name=\'' + d.name + '\']');
          $('.pie-chart-label').not(selected).addClass('text-gray400');
        },
        onmouseout: function (d, i) { 
          $('.pie-chart-label').removeClass('text-gray400');
        },
        color: function (color, d) {
          return colorsKey[d];
        }
      },
      pie: {
        expand: true,
        threshold: .2,
        // padAngle: .1,
        label: {show: false}
      },
      legend: {hide: true},
      tooltip: {show: false}
    });

    // Set chart data to allow for destroying chart before Turbo cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    $('#assessment_causes_chart').data('c3-chart', this.chartId);

    // Add labels
    let len = parseInt($('#assessment_causes_chart').attr('data-len'));
    let runningTotal = 0;
    let mid;
    let i = 0;
    while (i < len) {

      // Set weight
      let weight = parseFloat(pData[i]['weight']);
      let start = runningTotal;
      runningTotal += weight;
      mid = runningTotal - (weight / 2)

      // Set label positions
      let label = $('.pie-chart-label[data-id=\'' + pData[i]['id'] + '\']');
      let left;
      let top;
      let adj;
      let degree = mid / 100 * 360 * 0.0174532925;
      let x = Math.cos(degree) * 150;
      let y = Math.sin(degree) * 150;

      // Adjust if small
      if (weight < 4) {
        // Prev weight
        if (parseFloat(pData[i - 1]['weight']) < 10) {
          adj = 30 * (1 / (len - i))
          x += adj;
          // Add padding if needed
          $('#myCausesDesc').css('padding-bottom', adj + "px");
        }
      }

      if (mid <= 50) {
        top = -x + "px";
        left = y + "px";
      } else {
        label.addClass('.text-right');
        label.css('transform', 'translate(-100%, 0%)');
        top = -x + "px";
        left = y + "px";
      }

      label.css('top', top)
      label.css('left', left);

      // Next
      i++;
    };

    // Remove opacity from labels
    $('.pie-chart-labels').css('opacity', 1);

    return chart;

  }
};

