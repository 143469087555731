import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Pick an image and update an associated attribute in DB
export default function() {

  const firmUrl = $('body').attr('data-firm-url');
  const selectImageContainer = function() {

    // Select an image on click
    $(".admin-image-container").click(function() {
      // Toggle active classes
      $('.admin-image-container.active').not($(this)).removeClass('active');
      $(this).toggleClass('active');

      // If active, enable submit button
      if ($(this).hasClass('active')) {
        $('.select-image').prop('disabled', false)
        $('.select-image').attr('data-title', '')
      } else {
        $('.select-image').prop('disabled', true)
        $('.select-image').attr('data-title', 'Select an image')
      }

    });

  }

  selectImageContainer();

  // Remove an image
  $('.remove-admin-image').click(function() {
    $(this).closest('.image-group').find('input[type=\'hidden\']').val('');
    $(this).closest('.image-group').find('img').attr('src','');
  });

  // Set environment and account id for AWS path vars
  $('.admin-filepicker').each(function(i, elem) {
    let fileInput    	= $(elem);
    var progressBar  	= $("<div class='bar'></div>");
    var barContainer 	= $("<div class='progress'></div>").append(progressBar);
    let url = fileInput.data('url');
    let formData = fileInput.data('form-data');
    $('#imageUploadProgress').append(barContainer);
    fileInput.fileupload({
      fileInput:       	fileInput,
      url:             	fileInput.data('url'),
      type:            	'POST',
      autoUpload:       true,
      formData:         fileInput.data('form-data'),
      paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%')
      },
      start: function (e) {
        $('.modal-body.images-body').css('opacity', 0.3);
        Spinner($('.modal-content'));

        progressBar.
          css('background', '#8c81d1').
          css('display', 'block').
          css('width', '0%').
          text("Loading...");
      },
      done: function(e, data) {
        progressBar.text("Uploading done");

        // extract key from response
        let key = $(data.jqXHR.responseXML).find("Key").text();

        // Create image in local DB
			  let updateUrl = '/admin/images';
	      let formData = {'image': {'url': key, 'admin': true, 'image_type': 'admin'}};

	      // Update local attribute
        return $.ajax({
	        type: 'POST',
	        url: updateUrl,
	        dataType: "application/json",
	        data: formData,
	        complete(result) { 
            Turbo.visit(window.location.href);
	        },
	      });

      },
      fail: function(e, data) {
        progressBar.
          css("background", "red").
          text("Failed");
      }
    });

  });

}