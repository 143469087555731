import Spinner from 'src/javascripts/components/utilities/Spinner';
import Dashboards from 'src/javascripts/components/Dashboards';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import ImpactDistPortfolio from 'src/javascripts/components/charts/ImpactDistPortfolio';
import PortfolioItemsTable from 'src/javascripts/components/tables/PortfolioItemsTable';
import SdgsTable from 'src/javascripts/components/tables/SdgsTable';
import PortfolioAdverseIndicatorsPoll from 'src/javascripts/components/polling/PortfolioAdverseIndicatorsPoll';
import PortfolioAdverseIndicatorsTrend from 'src/javascripts/components/charts/PortfolioAdverseIndicatorsTrend';
import PortfolioAdverseIndicatorsTable from 'src/javascripts/components/tables/PortfolioAdverseIndicatorsTable';
import SdgContributionPieChart from 'src/javascripts/components/charts/SdgContributionPieChart';
import PortfolioItemMetricScoresTable from 'src/javascripts/components/tables/PortfolioItemMetricScoresTable';

export default class PortfoliosVantageLevelTwo {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Dashboards
    Dashboards();

    // Expand/minimize profile details
    ExpandContent();

    // Sticky sidebar scrolling
    StickySidebar();

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('.sidebar-title-info').removeClass('min-height');
      } else {
        $('.sidebar-title-info').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('.sidebar-title-info').removeClass('min-height');
    } else {
      $('.sidebar-title-info').addClass('min-height');
    }

    // If tag present
    let tag = $('#profileTabContent').attr('data-tag');
    if ((typeof tag !== 'undefined') && (tag.length > 0)) {
      let toExpand = $('#' + tag).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(tag)  
      }, 1000);
    }

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let modelId = isGenerating.attr('data-model-id');
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['scope'] = 'portfolio';
        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/check_import_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Completion percentage time periods
    $('#completionTimePeriod').change(function() {
      let val = $(this).val();
      let url = window.location.pathname;
      url = url + '?year=' + val + '&tag=questionnaireCompletionSection';
      Turbo.visit(url);
    });

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message').length !== 0) { importPoll(); }

    // If scorecards present
    if ($('.summary-scorecard').length !== 0) { 
      $('.summary-scorecard').each(function() {
        let totToday = 0;
        let countToday = 0;
        let totYe = 0;
        let countYe = 0;
        $(this).find('tbody tr:not(:last-child)').each(function() {
          let st = parseFloat($(this).find('.scorecard-circle.today').text());
          let sy = parseFloat($(this).find('.scorecard-circle.year-end').text());
          if (!isNaN(st)) {
            totToday += st;
            countToday += 1;
          }
          if (!isNaN(sy)) {
            totYe += sy;
            countYe += 1;
          }
        });
        if (totToday !== 0) {
          $(this).closest('.card').find('.total-today .dark').text(totToday.toFixed(1) + ' / ' + countToday.toFixed(1));
          $(this).closest('.card').find('.total-year-end .dark').text(totYe.toFixed(1) + ' / ' + countYe.toFixed(1));
        }
      });
    }

    // Company scorecards
    if ($('#companyScorecardList').length !== 0) { 
      $('#companyScorecardList .toggle-company-scorecard').click(function() {
        $(this).closest('.card').find('.card-body').toggleClass('minimized');
        $(this).closest('h5').find('.toggle-company-scorecard').toggleClass('d-none');
      });

      $('.company-scorecard tbody tr').click(function() {
        let url = $(this).attr('data-url');
        if (typeof url !== 'undefined') {
          window.open(url);
        }
      })
    }
    if ($('#moduleScorecardList').length !== 0) { 
      $('#moduleScorecardList .toggle-module-scorecard').click(function() {
        $(this).closest('.card').find('.card-body').toggleClass('minimized');
        $(this).closest('h5').find('.toggle-module-scorecard').toggleClass('d-none');
      });

      $('.module-scorecard tbody tr').click(function() {
        let url = $(this).attr('data-url');
        if (typeof url !== 'undefined') {
          window.open(url);
        }
      })
    }

    // Questionnaire benchmarking
    $('.benchmarking-category-list').on('shown.bs.collapse', function (e) {
      let container = $(this).find('.benchmarking-container');
      if (container.length > 0) {
        Spinner(container);
        let dataUrl = container.attr('data-url');
        let filters = {};
        let moduleId = container.attr('data-module-id');
        let token = container.attr('data-token');
        let itemId = container.attr('data-item-id');
        let questionnaireId = container.attr('data-questionnaire-id');
        if (typeof token !== 'undefined') {filters['token'] = token;}
        if (typeof itemId !== 'undefined') {filters['item_id'] = itemId;}
        if (typeof moduleId !== 'undefined') {filters["module_id"] = moduleId;}
        if (typeof questionnaireId !== 'undefined') {filters['questionnaire_id'] = questionnaireId;}
        $.ajax({
          type: "GET",
          dataType: "script",
          url: dataUrl + '.js?' + $.param(filters)
        });
      }
    })


    // Questionnaire completion
    $('#questionnaireCompletionList').on('shown.bs.collapse', function (e) {
      Spinner($('.questionnaire-completion-container'));
      let accountUrl = $('body').attr('data-account-url');
      let url = '/' + accountUrl + '/questionnaires/responses';
      let params = {};
      params['year'] = $('.questionnaire-completion-container').attr('data-year');
      params['status'] = $('.questionnaire-completion-container').attr('data-status');
      params['deadline'] = $('.questionnaire-completion-container').attr('data-deadline');
      params['portfolio_id'] = $('.questionnaire-completion-container').attr('data-portfolio-id');
      $.ajax({
        type: 'GET',
        dataType: "script",
        url: url + '.js?' + $.param(params),
      });
    });


    // Questionnaire modules show
    let container = $('#questionnaireModulesList').find('.benchmarking-container');
    if (container.length > 0) {
      Spinner(container);
      let dataUrl = container.attr('data-url');
      let filters = {};
      let token = container.attr('data-token');
      if (typeof token !== 'undefined') {filters['token'] = token;}
      $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js?' + $.param(filters)
      });
    }


    // Custom methodology on shown
    $('#customMethodologyRatingsList').on('shown.bs.collapse', function (e) {
      if ( typeof $('.custom-ratings-explorer') !== 'undefined' ) {
        let portfolioId = $('#customMethodologyRatingsList').attr('data-portfolio-id');
        let url = "/" + accountUrl + "/portfolios/" + portfolioId + "/ratings/custom_methodology_scorecard";
        let params = {};
        Spinner($('#customMethodologyRatingsList'));
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + $.param(params)
        });
      }
    })


    // Custom table on shown
    $('#customMethodologyTableList').on('shown.bs.collapse', function (e) {
      if ( typeof $('.questionnaire-scoring-table') !== 'undefined' ) {
        let portfolioId = $('#customMethodologyTableList').attr('data-portfolio-id');
        let url = "/" + accountUrl + "/portfolios/" + portfolioId + "/ratings/custom_methodology_table";
        let params = {};
        Spinner($('#customMethodologyTableList'));
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + $.param(params)
        });
      }
    })


    // Questionnaire gauges
    $('.questionnaire-gauge').click(function() {
      let recordType = $(this).attr('data-record-type');
      let recordId = $(this).attr('data-record-id');
      let activeSegments;
      let activeCategories;
      let activeElements;
      let visibleCategories;
      let visibleElements;
      let visibleQuestions;
      let activeCategoryId;
      let activeSegmentId;
      let activeElementId;

      // Action by record type
      if (recordType == 'segment') {

        // Set other segments as inactive
        $('.questionnaire-gauge[data-record-type=\'segment\']').removeClass('active').addClass('inactive');

        // Set active categories
        $('.questionnaire-gauge[data-record-type=\'category\']').removeClass('active').addClass('inactive d-none');
        visibleCategories = $('.questionnaire-gauge[data-record-type=\'category\'][data-methodology-segment-id=\'' + recordId + '\']');
        visibleCategories.removeClass('d-none');
        activeCategories = visibleCategories[0]
        $(activeCategories).addClass('active').removeClass('inactive');

        // Set active elements, hide others
        $('.questionnaire-gauge[data-record-type=\'element\']').removeClass('active').addClass('inactive d-none');
        activeCategoryId = $(activeCategories).attr('data-record-id');
        visibleElements = $('.questionnaire-gauge[data-record-type=\'element\'][data-methodology-category-id=\'' + activeCategoryId + '\']');
        visibleElements.removeClass('d-none');
        activeElements = visibleElements[0]
        $(activeElements).addClass('active').removeClass('inactive');

        // Set visible questions, hide others
        $('.questionnaire-gauge[data-record-type=\'question\']').addClass('d-none');
        activeElementId = $(activeElements).attr('data-record-id');
        visibleQuestions = $('.questionnaire-gauge[data-record-type=\'question\'][data-methodology-element-id=\'' + activeElementId + '\']');
        visibleQuestions.removeClass('d-none');

      } else if (recordType == 'category') {

        // Set active segments
        $('.questionnaire-gauge[data-record-type=\'segment\']').removeClass('active').addClass('inactive');
        let activeSegmentId = $(this).attr('data-methodology-segment-id');
        activeSegments = $('.questionnaire-gauge[data-record-type=\'segment\'][data-record-id=\'' + activeSegmentId + '\']');
        $(activeSegments).addClass('active').removeClass('inactive');

        // Set other categories as inactive
        $('.questionnaire-gauge[data-record-type=\'category\']').removeClass('active').addClass('inactive');

        // Set active elements, hide others
        $('.questionnaire-gauge[data-record-type=\'element\']').removeClass('active').addClass('inactive d-none');
        visibleElements = $('.questionnaire-gauge[data-record-type=\'element\'][data-methodology-category-id=\'' + recordId + '\']');
        visibleElements.removeClass('d-none');
        activeElements = visibleElements[0]
        $(activeElements).addClass('active').removeClass('inactive');

        // Set visible questions, hide others
        $('.questionnaire-gauge[data-record-type=\'question\']').addClass('d-none');
        activeElementId = $(activeElements).attr('data-record-id');
        visibleQuestions = $('.questionnaire-gauge[data-record-type=\'question\'][data-methodology-element-id=\'' + activeElementId + '\']');
        visibleQuestions.removeClass('d-none');

      } else if (recordType == 'element') {

        // Set other elements as inactive
        $('.questionnaire-gauge[data-record-type=\'element\']').removeClass('active').addClass('inactive');

        // Set active questions, hide others
        $('.questionnaire-gauge[data-record-type=\'question\']').removeClass('active').addClass('inactive d-none');
        visibleQuestions = $('.questionnaire-gauge[data-record-type=\'question\'][data-methodology-element-id=\'' + recordId + '\']');
        visibleQuestions.removeClass('d-none inactive').addClass('active');

      }

      // Update active for clicked gauge
      $(this).addClass('active').removeClass('inactive');

    })

  }

}