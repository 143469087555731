import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import DefaultChartTooltip from 'src/javascripts/components/charts/tooltips/DefaultChartTooltip';

export default class MetricComparisonChart extends Chart {

  render(data) {

    // Set a spinner to indicate chart loading
    Spinner('#metric_comparison_container');
    $('#metric_comparison_container').css('opacity', 0.25);

    // Set data and labels
    let pData = $('#metric_comparison_chart').attr('data-portfolio-score');
    let pDataHuman = $('#metric_comparison_chart').attr('data-portfolio-score-human');
    let aData = $('#metric_comparison_chart').attr('data-average-score');
    let aDataHuman = $('#metric_comparison_chart').attr('data-average-score-human');
    let dataLabel = $('#metric_comparison_chart').attr('data-label');
    let updateable = $('#metric_comparison_chart').attr('data-updateable');
    let benchName = $('#metric_comparison_chart').attr('data-benchmark-name');
    let featuredRecordName = $('#metric_comparison_chart').attr('data-featured-record-name');

    // Render chart
    const chart = c3.generate({
      bindto: '#metric_comparison_chart',
      data: {
        type: 'bar',
        columns: [[dataLabel, pData, aData]],
        labels: {
          format: function (v, id, i, j) {
            if (updateable === 'true') {
              return parseInt(v).toLocaleString() + ' ' + dataLabel;
            } else {
              if (i === 0) {
                return pDataHuman;
              } else {
                return aDataHuman;
              }
            }
          }
        },
        color: function (color, d) {
          if (d.x === 0) {
            return '#efedff';
          } else {
            return '#dee2e6';
          }
        }
      },
      legend: {hide: true},
      axis: {
        x: {type: 'category', categories: [featuredRecordName, benchName]},
        y: {show: false}
      },
      bar: {width: {ratio: 0.8}},
      tooltip: {show: false},

      onrendered() {
        $('#metric_comparison_container').css('opacity', 1);
        $('#metric_comparison_container').find('.spinner-container').remove();
      }

    });

    // Set chart data to allow for destroying chart before Turbo cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    $('#metric_comparison_chart').data('c3-chart', chart);

    return chart;

  }
};
