import QuestionnaireResponseAnswersTable from 'src/javascripts/components/tables/QuestionnaireResponseAnswersTable';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class QuestionnairesResponsesAnswersIndex {

  constructor() {}

  render() {

	  // Set account id
	  const accountUrl = $('body').attr('data-account-url');

	  // Questionnaires and companies search
	  filterTypeahead('.companies-search', 'companies', '/' + accountUrl + '/companies/search_share_classes?', 382, 'generic');
	  filterTypeahead('#questions_search', 'questions', '/' + accountUrl + '/questionnaires/questions/search?', 282, 'generic');
	  filterTypeahead('#responses_search', 'responses', '/' + accountUrl + '/questionnaires/responses/search?', 403, 'generic');

    // Render table
    if ( $('#questionnaire_response_answer_trends_table_wrapper').length === 0 ) {
      let table = new QuestionnaireResponseAnswersTable($('#questionnaire_response_answer_trends_table'));
      $.when( table.render() ).then( status => table.setUp('questionnaire_response_answer_trends_table') )
    }

    // Render table
    $('#changeLogList').on('shown.bs.collapse', function (e) {
      if ( $('#questionnaire_changes_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#questionnaire_changes_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_changes_table') )
      }
    })

    // Expand when click on table of contents item
    $('.sticky-sidebar a.scroll-to-section').click(function(e) {
      e.preventDefault();
      let sectionId = $(this).attr('data-tag');
      setTimeout(function() {
        ScrollToTag(sectionId);  
      }, 500);
    });

    // Auto-focus on expanding dropdown
    $('.dropdown').on('shown.bs.dropdown', function() {
    	$(this).find('.typeahead').focus();
    });

    // Reload with attrs
    let reloadWithSelectedParams = function() {
	    let url = '/' + accountUrl + '/questionnaires/responses/answers';
      let params = {};
      params['company_id'] = $('#questionnaire_response_answer_trends_table').attr('data-company-id');
      params['questionnaire_id'] = $('#questionnaire_response_answer_trends_table').attr('data-questionnaire-id');
	    params['question_id'] = $('#questionnaire_response_answer_trends_table').attr('data-question-id');
      Turbo.visit(url + '?' + $.param(params));
    }

    // On selecting an item
    $('.companies-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    	$('#questionnaire_response_answer_trends_table').attr('data-company-id', suggestion.company_id);
    	reloadWithSelectedParams();
    })

    // On selecting a questionnaire
    $('.questionnaires-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    	$('#questionnaire_response_answer_trends_table').attr('data-questionnaire-id', suggestion.value);
    	reloadWithSelectedParams();
    })

	  // On selecting a question
	  $('#questions_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    $('#questionnaire_response_answer_trends_table').attr('data-question-id', suggestion.value);
    	reloadWithSelectedParams();
	  })


	  // On selecting a response
	  $('#responses_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let rId = suggestion.value;
	    
	    // Download excel formatted data
	    let url = "/" + accountUrl + "/data-collection/responses/" + rId + "/answers";
		  $.ajax({
		    type: "GET",
		    dataType: "script",
		    url: url + '.js'
		  });

		  // Blur
		  $(this).typeahead('val','').blur();

	  });

  }

}