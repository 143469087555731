import ImagePicker from 'src/javascripts/components/forms/ImagePicker';

export default class SettingsAccountsImagesIndex {

  constructor() {}

  render() {

    // Upload images
    ImagePicker(["png", "jpg", "jpeg"]);

    // Select an image on click
    $(".image-container").click(function() {
      
      // Toggle active classes
      $('.image-container.active').not($(this)).removeClass('active');
      $(this).toggleClass('active');

      // If active, enable submit button
      if ($(this).hasClass('active')) {
        $('.submit-button').prop('disabled', false)
        $('.submit-button').attr('data-title', '')
      } else {
        $('.submit-button').prop('disabled', true)
        $('.submit-button').attr('data-title', 'Select an image')
      }

    });

    // Update on click
    $('.submit-button').click(function() {
      let url = $(this).attr('data-update-url');
      let backPath = $(this).attr('data-back-path');
      let accountUrl = $(this).attr('data-account-url');
      if (typeof backPath === 'undefined') {backPath = "/" + accountUrl;}
      let model = $(this).attr('data-model');
      let col = $(this).attr('data-col');
      let formData = {}
      let imageName;
      formData[model] = {}
      formData[model][col] = $('.image-container.active').attr('data-image-url');
      if (col == 'cover_url') {
        // Reset image cover top and cover left attrs
        formData[model]['cover_top'] = 0;
        formData[model]['cover_left'] = 0;
        imageName = 'Cover image';
      } else if (col == 'report_cover_url') {
        // Reset image cover top and cover left attrs
        formData[model]['report_cover_top'] = 0;
        formData[model]['report_cover_left'] = 0;        
        imageName = 'Report banner image';
      } else if (col == 'report_logo_url') {
        imageName = 'Report logo image';
      } else if (col == 'logo_url') {
        imageName = 'Logo image';
      } else {
        imageName = 'Image';
      }

      // Update local attribute
      return $.ajax({
        type: 'PATCH',
        url: url,
        dataType: "application/json",
        data: formData,
        complete(result) { 
          let notice;
          if (result.status === 200) {
            notice = imageName + " saved";
          } else {
            notice = "There was a problem, please contact us for support";
          }
          let url = backPath;
          if (url.indexOf("?")> -1) {
            url = url + "&notice=" + notice;
          } else {
            url = url + "?notice=" + notice;
          }
          return Turbo.visit(url);
        },
      });


    })

    // Show modal if present on page (if user signed in)
    $('#accountImagesModal').modal({backdrop: 'static', keyboard: false});

  }

}