import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, genericDisplayTemplate, causeTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'summernote/dist/summernote-bs4.min.js'
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {
  filterTypeahead('#metrics_search', 'metrics', '/metrics/search?', 576, 'cause');

  // Create a filter on select
  $('#metrics_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    $('#metric_id').val(suggestion.value);
  });

  // On clear typeahead
  $('.clear-metric-typeahead').click(function() {
    $('#metric_id').val("");
    $('#metrics_search').typeahead("val", "");
    $('#metrics_search').focus();
  })

	$('.question-type-card').click(function() {
		let qTypeId = $(this).attr('data-question-type-id');
		let qTypeName = $(this).attr('data-question-type-name');
		$(this).closest('.form-group').find('input[type=\'hidden\']').val(qTypeId);
		$('.question-type-card .card-body.bg-primary-light').removeClass('bg-primary-light');
		$(this).find('.card-body').addClass('bg-primary-light');

		// Opacity and spinner of modal
		let modal = $(this).closest('.modal');
		modal.find('.modal-body').css('opacity', 0.25);
		Spinner(modal);

		// If dropdown, unhide dropdown area
		if ((qTypeName == "dropdown") || (qTypeName == "dropdown_multiselect")) {
			$('.dropdown-options-section').removeClass('d-none');
		} else {
			$('.dropdown-options-section').addClass('d-none');
		}

		// If numeric, unhide high-low value area
		let numericArray = ['dropdown', 'dropdown_multiselect', 'percent', 'integer', 'usd', 'decimal'];
		if (jQuery.inArray(qTypeName, numericArray) !== -1) {
			$('.high-low-value').removeClass('d-none');
		} else {
			$('.high-low-value').addClass('d-none');
		}

		setTimeout(function() {
			modal.find('.modal-body').css('opacity', 1);
			modal.find('.spinner-container').remove();
		}, 1000)

	})

	$('.question-options-source-card').click(function() {
		let optionsSource = $(this).attr('data-options-source');
		$(this).closest('.form-group').find('input[type=\'hidden\']').val(optionsSource);
		$('.question-options-source-card .card-body.bg-primary-light').removeClass('bg-primary-light');
		$(this).find('.card-body').addClass('bg-primary-light');

		// Opacity and spinner of modal
		let modal = $(this).closest('.modal');
		modal.find('.modal-body').css('opacity', 0.25);
		Spinner(modal);

		// If custom, unhide
		if (optionsSource == "custom") {
			$('.custom-options').removeClass('d-none');
		} else {
			$('.custom-options').addClass('d-none');
		}

		setTimeout(function() {
			modal.find('.modal-body').css('opacity', 1);
			modal.find('.spinner-container').remove();
		}, 1000)

	})

}