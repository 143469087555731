import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import {noResultsTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class SettingsAccountsRestrictedAssetsIndex {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Restricted assets table and functions to add/remove
    let table = new DefaultTable( $('#restricted_assets_table') );
    $.when( table.render('restricted_assets_table') ).then( status => table.setUp('restricted_assets_table') );

    // Set companies and funds bloodhounds
    const companiesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/companies/search?query=%QUERY',
        wildcard: "%QUERY",
        transform: function(d) { return d.companies; }
      }});
    companiesBloodhound.initialize();

    $('#companies_search').typeahead({
      hint: false,
      highlight: true,
      minLength: 0,
    }, {
      name: 'companies',
      display: 'name',
      limit: 112,
      source: companiesBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
            return logoTemplate(el.name, el.symbol, el.logo_url);
          } else {
            return genericDisplayTemplate(el.name, el.symbol);
          }
        }
      },
    });

    // On select of a typeahead suggestion
    $('.restricted-assets-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let params = {};
      params['company_id'] = suggestion.value;
      $.ajax({
        type: "POST",
        dataType: "application/json",
        url: '/' + accountUrl + '/restricted_assets?' + $.param(params),
        complete(result) {
          let responseText = JSON.parse(result.responseText);
          let url = '/' + accountUrl + '/restricted_assets/' + responseText.restricted_asset_id + '/edit';
          Turbo.visit(url);
        }
      });

    });

  }

}