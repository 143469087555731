import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import {genericDisplayTemplate, noResultsTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class NotificationsSettingsIndex {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Initiate portfolios typeahead
    const portfoliosBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/' + accountUrl + '/portfolios/search?query=%QUERY',
        wildcard: "%QUERY"
      }
    });
    portfoliosBloodhound.initialize();
    $('.find-notification-portfolio-typeahead').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'models',
      display: 'name',
      limit: 54,
      source: portfoliosBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.name);
          }
        }
      },
    });

    // Create a notification portfolio on typeahead select
    $('.find-notification-portfolio-typeahead').bind('typeahead:beforeselect', function(ev, suggestion) {

      // Prevent default
      ev.preventDefault();

      // Return if has class disabled
      if ($(this).hasClass('disabled')) {return;}

      // Add spinner and opacity to table
      Spinner($('.portfolios-limit'));
      $('.portfolios-limit').find('.card-body').css('opacity', 0.25);

      // Build ajax call to add/remove selection from model filters
      let params = {};
      params['portfolio_id'] = suggestion.value;
      let url = '/notifications/portfolios?' + $.param(params);

      // Create a filter for model
      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'application/json',
        complete(result) { 
          if (result.status === 200) {
            Turbo.visit("/notifications/settings?notice=Added portfolio");
          } else {
            ToastCustom('Ethos', 'There was a problem, please contact us for support');            
          }
        },
      });

    });


    // Update user's settings preferences on select
    let $settingInput = $('input.notification-setting-input');
    $settingInput.change(function() {
      
      // Set attrs
      let settingId = $(this).attr('data-setting-id');
      let setting = $(this).attr('data-setting');
      let approvalRequestId = $(this).attr('data-approval-request-id');

      // Set params as true or false
      let params = {};

      if (typeof approvalRequestId !== "undefined") {
        params['notification_approval_request'] = {};
        if ( $(this)[0].checked ) {
          params['notification_approval_request'][setting] = 'true'
        } else {
          params['notification_approval_request'][setting] = 'false'
        }

      } else {
        params['notification_setting'] = {};
        if ( $(this)[0].checked ) {
          params['notification_setting'][setting] = 'true'
        } else {
          params['notification_setting'][setting] = 'false'
        }        

      }

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/notifications/settings/' + settingId + '.js?' + $.param(params),
        complete(result) { 
          ToastCustom('Success', 'Updated your notification preference');
        }
      })
    });

    // Update user's settings preferences on select
    let $settingSelect = $('select.notification-setting-select');
    $settingSelect.change(function() {
      
      // Set attrs
      let settingId = $(this).attr('data-setting-id');
      let setting = $(this).attr('data-setting');

      // Val
      let val = $(this).val();

      // Set params as true or false
      let params = {};
      params['notification_setting'] = {}

      if ( $(this)[0].checked ) {
        params['notification_setting'][setting] = val;
      } else {
        params['notification_setting'][setting] = val;
      }

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/notifications/settings/' + settingId + '.js?' + $.param(params),
        complete(result) { 
          ToastCustom('Success', 'Updated your notification preference');
        }
      })
    });


    // Update user's settings preferences for min threshold
    let $thresholdSubmit = $('#updateSavedListThreshold');
    $thresholdSubmit.click(function() {
      
      // Set attrs
      let settingId = $(this).attr('data-setting-id');
      let setting = $(this).attr('data-setting');
      let threshold = $('#notifySavedListThreshold').val();

      // Set params as true or false
      let params = {};
      params['notification_setting'] = {}
      params['notification_setting'][setting] = threshold

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/notifications/settings/' + settingId + '.js?' + $.param(params),
        complete(result) { 
          ToastCustom('Success', 'Updated your minimum Impact Score preference');
        }
      })
    });

    // Update user's email frequency on select
    let $emailFreqSelect = $('#emailFrequencySelect');
    $emailFreqSelect.change(function() {
      
      // Set selection
      let settingId = $(this).attr('data-setting-id');
      let setting = $(this).attr('data-setting');
      let freq = $('#emailFrequencySelect').val();

      // Set params as true or false
      let params = {};
      params['notification_setting'] = {}
      params['notification_setting'][setting] = freq

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/notifications/settings/' + settingId + '.js?' + $.param(params),
        complete(result) { 
          ToastCustom('Success', 'Updated your notifications email preference');
        }
      })
    });


  }

}