import {companiesBloodhound, filterTypeahead, filterTypeaheadNoCache, screensTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class ComplianceApprovalRulesEdit {

  constructor() {}

  render() {
    let accountUrl = $('body').attr('data-account-url');

    // Search 
    screensTypeahead();
    filterTypeaheadNoCache('#search_controversy_categories', 'controversy_categories', '/' + accountUrl + '/companies/controversies/search_categories?', 51, 'cause');
    filterTypeahead('#search_metrics', 'metrics', '/metrics/search?', 550, 'cause');
    let formulasUrl = '/formulas/search?risk=all&include_non_system=true&';
    filterTypeahead('#search_formulas', 'formulas', formulasUrl, 279, 'cause');

    $('.rule-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $('.rule-search').not($(this)).typeahead('val', '');
      $('.hidden-field').val('');
      $(this).closest('.form-group').find('.hidden-field').val(suggestion.value);

      // Set reload params
      let url = window.location.href.split('?')[0];
      let params = {};
      let name = $('#approval_rule_name').val();
      if (name !== '') {params['name'] = name}
      let screenId = $('#approval_rule_screen_id').val();
      if (screenId !== '') {params['screen_id'] = screenId}
      let contId = $('#approval_rule_controversy_category_id').val();
      if (contId !== '') {params['controversy_category_id'] = contId}
      let metricId = $('#approval_rule_metric_id').val();
      if (metricId !== '') {params['metric_id'] = metricId}
      let formulaId = $('#approval_rule_formula_id').val();
      if (formulaId !== '') {params['formula_id'] = formulaId}

      // Reload
      Turbo.visit(url + '?' + $.param(params));

    });


  }
}