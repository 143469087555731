import * as c3 from 'c3';
import * as d3 from 'd3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import DefaultChartTooltip from 'src/javascripts/components/charts/tooltips/DefaultChartTooltip';

export default class SdgContributionPieChart extends Chart {

  getData() {

    // Get data
    const url = this.el.attr('data-api-url');
    return $.getJSON(url);
  }

  render(data) {

    // Set chartId
    const chartId = this.chartId;

    // Show no data message if none available
    // data[1] is the chart data; data[0] is dates array
    if ((data === null) || (data.alignedWeights === null)) { 
      return NoDataMessage(this.el); 
    }

    // Set data and labels
    let columns = data.alignedWeights;

    // Drop last one (other) for chart
    let lastCol = columns[columns.length - 1];
    if (lastCol[0] === "Other SDGs") { columns = columns.slice(0, -1); }

    // Contributors percent
    let text = "This graphic shows where the portfolio has the most potential to contribute to the SDGs, based on scores for each SDG. ";
    text += "Where applicable, the graphic also considers holdings that Ethos has determined are focused on \'engagement\', i.e., portfolio manager(s) are actively working to improve impact on an SDG.";
    $('.contributors-percent').empty().append(text);

    // Set colors key
    let colorsKey = {}
    $.each(data.alignedWeights, function(i,o) {
      let name = o[0];
      let color = o[2];
      colorsKey[name] = color;
    })
    colorsKey["None"] = "#f8f9fa";

    // Add SDG labels
    $('.sdg-pie-chart-labels').empty();
    $.each(data.alignedWeights, function(i, goal) {
      let div = "<div class=\'d-flex w-100 py-1 align-items-center border-dashed pie-chart-label\' data-name=\'" + goal[0] + "\'>";
      div += "<div class=\'mr-2\'><span class=\'pie-chart-label-box border mr-2\' style=\'padding: 0px 10px; background-color: " + goal[2] + "\'></span>" + goal[0] + "</div>";
      div += "<div class=\'ml-auto mr-2\'>" + goal[1] + "%</div>";
      div += "</div>";
      $('.sdg-pie-chart-labels').append(div);
    });

    // Render chart
    const chart = c3.generate({
      bindto: '#' + chartId,
      data: {
        type: 'pie',
        columns: columns,
        onmouseover: function (d, i) { 
          let selected = $('.pie-chart-label[data-name=\'' + d.name + '\']');
          selected.addClass('bolded');
          $('.pie-chart-label').not(selected).addClass('opaque').removeClass('.bolded');
        },
        onmouseout: function (d, i) { 
          $('.pie-chart-label').removeClass('opaque').removeClass('bolded');
        },
        color: function (color, d) {
          return colorsKey[d];
        }
      },
      pie: {
        expand: true,
        threshold: 0.1,
        label: {show: false}
        // padAngle: .1,
      },
      legend: {hide: true},
      tooltip: {show: false}
    });

    // // Set chart data to allow for destroying chart before Turbo cache
    // // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    $('#' + chartId).data('c3-chart', this.chartId);

    // Remove spinner
    $('#' + chartId).closest('.card').find('.spinner-container').remove();

    // Add legend interaction
    $('.pie-chart-label').on('mouseover', function() {
      chart.focus($(this).attr('data-name'));
      $('.pie-chart-label').not($(this)).addClass('opaque').removeClass('bolded');
      $(this).removeClass('opaque').addClass('bolded');
    }).on('mouseout', function() {
      chart.revert();
      $('.pie-chart-label').removeClass('bolded opaque');
    });

    // Remove opacity from labels
    $('.pie-chart-labels').css('opacity', 1);

    return chart;

  }
};

