import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate, noResultsTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class SettingsAccountsMethodologiesEdit {

  constructor() {}

  render() {
  	let accountUrl = $('body').attr('data-account-url');
  	let methodologyId = $('#methodologyTab').attr('data-methodology-id');
    let sectorId = $('#methodologyTab').attr('data-sector-id');
    let formulaId = $('#methodologyTab').attr('data-formula-id');
    let causeId = $('#methodologyTab').attr('data-cause-id');

    // Add a new pillar (formula) on select of typeahead
    let formulasUrl = '/formulas/search?pillars=true';
    filterTypeahead('.impact-formulas-name-short-filter', 'formulas', formulasUrl, 279, 'cause');
    $('.impact-formulas-name-short-filter').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
      $('#methodologyWeightsSection').find('.card-body').css('opacity', 0.25);
      Spinner($('#methodologyWeightsSection').find('.card'));
      let params = {};
      params['formula_id'] = suggestion.value;
      params['sector_id'] = sectorId;
      let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/weights?' + $.param(params);
      $.ajax({
        type: 'POST',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/edit?sector_id=' + sectorId + '&formula_id=' + formulaId;
          Turbo.visit(url);
        },
      });
    });

    // On add a new scoring range
    $('#addScoringRange').click(function(ev) {
      $('#add_scoring_range').val('true');
      $(this).closest('form').submit();
    })

    $('#shouldNormalizeRatings').change(function() {
      let val = $(this).val();
      if (val === "true") {
        $('#normalizationPercentageDiv').removeClass('d-none');
      } else {
        $('#normalizationPercentageDiv').addClass('d-none');
      }
    })

    // Update weight of formula on blur
    $('#methodologyWeightsSection .methodology-weight').blur(function() {
      let id = $(this).attr('data-id');
      let params = {};
      params['weighting'] = $(this).val();
      let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/weights/' + id + '?' + $.param(params);
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
          let totalWeight = responseText.weight.toFixed(1);
          $('#methodologyWeightsSection .total-weight').empty().append(totalWeight + '%');
        },
      });
    })

    // Add a new cause on select of typeahead
	  let causesUrl = '/causes/search?risk=all&sub_only=true&include_non_system=true';
	  filterTypeahead('.impact-causes-name-short-filter', 'causes', causesUrl, 279, 'cause');
	  $('.impact-causes-name-short-filter').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
      $('#formulaWeightsSection').find('.card-body').css('opacity', 0.25);
      Spinner($('#formulaWeightsSection').find('.card'));
      let params = {};
      let causeId = suggestion.value;
      params['formula_id'] = formulaId;
      params['cause_id'] = causeId;
      params['sector_id'] = sectorId;
      let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/formula_weights?' + $.param(params);
      $.ajax({
        type: 'POST',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/edit?sector_id=' + sectorId + '&formula_id=' + formulaId + '&cause_id=' + causeId;
          Turbo.visit(url);
        },
      });
	  });

    // Update weight of a cause on blur
    $('#formulaWeightsSection .formula-weight').blur(function() {
      let id = $(this).attr('data-id');
      let params = {};
      params['weighting'] = $(this).val();
      let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/formula_weights/' + id + '?' + $.param(params);
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
          let totalWeight = responseText.weight.toFixed(1);
          $('#formulaWeightsSection .total-weight').empty().append(totalWeight + '%');
        },
      });      
    })

    // Add a new metric on select
    let metricsUrl = '/metrics/search?risk=all';
    filterTypeahead('.metrics-name-short-filter', 'metrics', metricsUrl, 388, 'cause');
    $('.metrics-name-short-filter').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
      $('#metricWeightsSection').find('.card-body').css('opacity', 0.25);
      Spinner($('#metricWeightsSection').find('.card'));
      let params = {};
      params['cause_id'] = causeId;
      params['metric_id'] = suggestion.value;
      params['sector_id'] = sectorId;
      let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/metric_weights?' + $.param(params);
      $.ajax({
        type: 'POST',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/edit?formula_id=' + formulaId + '&cause_id=' + causeId + '&sector_id=' + sectorId;
          Turbo.visit(url);
        },
      });
    });

    // Update weight of a metric on blur
    $('#metricWeightsSection .metric-weight').blur(function() {
      let id = $(this).attr('data-id');
      let params = {};
      params['weighting'] = $(this).val();
      let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/metric_weights/' + id + '?' + $.param(params);
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
          let totalWeight = responseText.weight.toFixed(1);
          $('#metricWeightsSection .total-weight').empty().append(totalWeight + '%');
        },
      });      
    })

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let methodologyId = isGenerating.attr('data-methodology-id');
        let params = {};
        params['methodology_id'] = methodologyId;
        let url = '/' + accountUrl + '/methodologies/' + methodologyId + '/check_import_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message').length !== 0) { importPoll(); }


    // Set sector
    $('#methodologySector').change(function() {
      let sectorId = $(this).find(":selected").attr('data-sector-id');
      Turbo.visit('/' + accountUrl + '/methodologies/' + methodologyId + '/edit?sector_id=' + sectorId);
    });

  }

}