import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class ClientsHouseholdsEdit {

  constructor() {}

  render() {

    const accountUrl = $('body').attr('data-account-url');

    const bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/' + accountUrl + '/clients/search?query=%QUERY',
        wildcard: "%QUERY"
      }});

    bloodhound.initialize();

    let emptyTemplate = '<div class="empty-message">Unable to find that</div>';

    let clientTemplate = (name) => 
      '<div>' + name + '</div>';

    $('#add_client_typeahead').typeahead({
      hint: true,
      highlight: true,
      minLength: 0
    }, {
      name: 'typeahead',
      display: 'name',
      limit: 44,
      source: bloodhound.ttAdapter(),
      templates: {
        empty: emptyTemplate,
        suggestion(el) {
          return clientTemplate(el.name);
        }
      }
    });

    $('#add_client_typeahead').bind('typeahead:beforeselect', function(ev, suggestion) { 
	    $('.modal-body').css('opacity', 0.2);
	    Spinner($('.modal-content'));
      let url = '/' + accountUrl + '/clients/' + suggestion.value;
      let params = {}
      let householdId = $('#add_client_typeahead').attr('data-household-id');
      params['client'] = {};
      params['client']['household_id'] = householdId;

	    $.ajax({
	      type: 'PUT',
	      dataType: 'application/json',
	      url: url + '?' + $.param(params),
	      complete(result) { 
	      	Turbo.visit(window.location.toString(), { action: 'replace' })
	      },
	    });
    })

    $('.remove-client-household').click(function() { 
	    $('.modal-body').css('opacity', 0.2);
	    Spinner($('.modal-content'));
	    let clientId = $(this).attr('data-client-id');
      let url = '/' + accountUrl + '/clients/' + clientId;
      let params = {}
      params['client'] = {};
      params['client']['household_id'] = '';

	    $.ajax({
	      type: 'PUT',
	      dataType: 'application/json',
	      url: url + '?' + $.param(params),
	      complete(result) { 
	      	Turbo.visit(window.location.toString(), { action: 'replace' })
	      },
	    });
    })

  }

}