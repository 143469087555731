import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';
import QuestionnaireResponseApprovalRequestsTable from 'src/javascripts/components/tables/QuestionnaireResponseApprovalRequestsTable';
import QuestionnaireResponsesTable from 'src/javascripts/components/tables/QuestionnaireResponsesTable';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  // Set account id
  const accountUrl = $('body').attr('data-account-url');
  const dashboardId = $('#dashboardModulesModal').attr('data-dashboard-id');

  // Questionnaire completion
  if ($('.questionnaire-completion-container').length > 0) {
    Spinner($('.questionnaire-completion-container'));
    let url = '/' + accountUrl + '/questionnaires/responses';
    let params = {};
    params['year'] = $('.questionnaire-completion-container').attr('data-year');
    params['status'] = $('.questionnaire-completion-container').attr('data-status');
    params['deadline'] = $('.questionnaire-completion-container').attr('data-deadline');
    params['portfolio_id'] = $('.questionnaire-completion-container').attr('data-portfolio-id');
    $.ajax({
      type: 'GET',
      dataType: "script",
      url: url + '.js?' + $.param(params),
    });
  }

  // Tooltip
  $('.dashboard-item-card .dropdown[data-toggle=\'tooltip\']').click(function() {
    $(this).tooltip('hide');
  })

  // Completion percentage time periods
  $('#completionTimePeriod').change(function() {
    let val = $(this).val();
    let url = window.location.pathname;
    url = url + '?year=' + val;
    Turbo.visit(url);
  });

  // Controversies table
  if (($('#cont_table').length > 0) && ($('#cont_table tbody td').length === 0)) {
    Spinner($('#contListTab .card-body'));
    ResearchFiltering();
  }

  // Notifications table
  if (($("#notifications_table").length !== 0) && ($("#notifications_table_wrapper").length === 0)) {
    let table = new DefaultTable($('#notifications_table'));
    $.when( table.render() ).then( status => table.setUp('notifications_table') )
  }

  // Questions and portfolio items search
  filterTypeahead('#items_search', 'portfolio_items', '/' + accountUrl + '/portfolios/items/search?', 382, 'generic');
  filterTypeahead('.users-search', 'users', '/' + accountUrl + '/users/search?', 282, 'generic');
  filterTypeahead('#questionnaires_search', 'questionnaires_search', '/' + accountUrl + '/questionnaires/search?', 282, 'generic');

  // On selecting a user
  $('.users-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = $(location).attr("pathname");
    let params = {};
    let existingUserIds = $('#questionnaire_responses_table').attr('data-user-id');
    let uids = suggestion.value;
    if (typeof existingUserIds !== 'undefined') {uids = uids + ',' + existingUserIds;}
    params['questionnaire_user_id'] = uids;
    params['item_id'] = $('#questionnaire_responses_table').attr('data-item-id');
    params['status'] = $('#questionnaire_responses_table').attr('data-status');
    params['deadline'] = $('#questionnaire_responses_table').attr('data-deadline');
    params['questionnaire_id'] = $('#questionnaire_responses_table').attr('data-questionnaire-id');
    Turbo.visit(url + '?' + $.param(params));
  })

  // On selecting a response
  $('#items_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = $(location).attr("pathname");
    let params = {};
    let existingItemIds = $('#questionnaire_responses_table').attr('data-item-id');
    let iids = suggestion.value;
    if (typeof existingItemIds !== 'undefined') {iids = iids + ',' + existingItemIds;}
    params['item_id'] = iids;
    params['status'] = $('#questionnaire_responses_table').attr('data-status');
    params['questionnaire_id'] = $('#questionnaire_responses_table').attr('data-questionnaire-id');
    params['deadline'] = $('#questionnaire_responses_table').attr('data-deadline');
    let userId = $('#questionnaire_responses_table').attr('data-user-id');
    if (typeof userId === 'undefined') {
      params['questionnaire_user_id'] = 'all';
    } else {
      params['questionnaire_user_id'] = userId;
    }
    Turbo.visit(url + '?' + $.param(params));
  })

  // On selecting a questionnaire
  $('#questionnaires_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = $(location).attr("pathname");
    let params = {};
    let existingQuestionnaireIds = $('#questionnaire_responses_table').attr('data-questionnaire-id');
    let iids = suggestion.value;
    if (typeof existingQuestionnaireIds !== 'undefined') {iids = iids + ',' + existingQuestionnaireIds;}
    params['questionnaire_id'] = iids;
    params['item_id'] = $('#questionnaire_responses_table').attr('data-item-id');
    params['status'] = $('#questionnaire_responses_table').attr('data-status');
    params['deadline'] = $('#questionnaire_responses_table').attr('data-deadline');
    let userId = $('#questionnaire_responses_table').attr('data-user-id');
    if (typeof userId === 'undefined') {
      params['questionnaire_user_id'] = 'all';
    } else {
      params['questionnaire_user_id'] = userId;
    }
    Turbo.visit(url + '?' + $.param(params));
  })

  // Questionnaire responses table
  if ( $('#questionnaire_responses_table_wrapper').length === 0 ) {
    let table = new QuestionnaireResponsesTable($('#questionnaire_responses_table'));
    $.when( table.render() ).then( status => table.setUp('questionnaire_responses_table') )

  }

  if ($('#questionnaire_response_approval_requests_table_wrapper').length === 0) {
    let table = new QuestionnaireResponseApprovalRequestsTable($('#questionnaire_response_approval_requests_table'));
    $.when( table.render() ).then( status => table.setUp('questionnaire_response_approval_requests_table') )
  }

  // Check for passed deadlines
  if ($('.check-passed-deadlines').length !== 0) {
    let url = '/' + accountUrl + '/questionnaires/responses/check_passed_deadlines';
     $.ajax({
      type: 'GET',
      dataType: "script",
      url: url + '.js'
    });
  }


  // On close, reload if changed
  $('#dashboardModulesModal').on('hide.bs.modal', function() {
    if ($('#dashboardModulesModal').attr("data-changed") === "true") {
      Turbo.visit(window.location);        
    }
  })

  // Overall risk on expand
  if ($('#overallRiskChartContainer').length !== 0) {
    let cardList = $('#overallRiskChartContainer').closest('.list-card');
    let existingChart = $('#overallRiskChartContainer').find('.impact-scale');
    if (existingChart.length === 0) {
      Spinner(cardList);
      let formulaId = $('#overallRiskChartContainer').attr('data-formula-id');
      let featuredRecordId = $('#overallRiskChartContainer').attr('data-featured-record-id');
      let url = '/dashboards/' + dashboardId + '/analyses/overall_risk';
      let params = {};
      params['formula_id'] = formulaId;
      params['featured_record_id'] = featuredRecordId;
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });
      $(document).on("turbo:before-cache", function() {
        $('#overallRiskChartContainer').empty();
      })
    }
  };

  // Screens
  if ($('#screensContainer').length !== 0) {
    let el = $('#screensContainer');
    let cardBody = el.closest('.card-body');
    let existingScreens = cardBody.find('.screens-info');
    if (existingScreens.length === 0) {
      Spinner(cardBody);
      let featuredRecordId = $('#screensContainer').attr('data-featured-record-id');
      let url = '/dashboards/' + dashboardId + '/analyses/screens.js?';
      let params = {};
      params['featured_record_id'] = featuredRecordId;
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + $.param(params)
      });
      $(document).on("turbo:before-cache", function() {
        $('#screensContainer').empty();
      })
    }
  };


  // Update user's settings preferences on select
  let $settingInput = $('input.dashboard-inclusion-input');
  if (typeof $settingInput !== 'undefined') {
    $settingInput.change(function() {
      $('#dashboardModulesModal .modal-body').css('opacity', 0.25)
      Spinner($('#dashboardModulesModal .modal-content'));

      // Set params 
      let inclusionId = $(this).attr('data-inclusion-id');
      let params = {};
      params['dashboard_inclusions'] = {}
      if ( $(this)[0].checked ) {
        params['dashboard_inclusions']['enabled'] = true;
      } else {
        params['dashboard_inclusions']['enabled'] = false;
      }

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/dashboards/' + dashboardId + '/inclusions/' + inclusionId + '.js?' + $.param(params),
        complete(result) { 
          $('#dashboardModulesModal').attr('data-changed', 'true');
          $('#dashboardModulesModal .modal-content').find('.spinner-container').remove();
          $('#dashboardModulesModal .modal-body').css('opacity', 1)
        }
      })

    });

  }

  // Draggable dashboard items
  $('#sortableDashboardInclusions').off().sortable({
    placeholder: "available-module-space",
    handle: 'h5.drag-module',
    update: function(event, ui) {
      // Opacity to indicate loading
      $('#dashboardModulesModal .modal-body').css('opacity', 0.25)
      Spinner($('#dashboardModulesModal .modal-content'));

      // Set url and params for updating in database
      let sortOrder = $(this).sortable('toArray');
      let url = '/dashboards/' + dashboardId + '/inclusions/update_all?';
      let params = {};
      params["user_dashboard_inclusions"] = {}

      // Iterate through sections
      $.each(sortOrder, function(ind, moduleId) {

        // Set li and params
        let li = $('#' + moduleId);
        let id = li.attr('data-inclusion-id');
        li.attr('data-sort-order', ind + 1);
        params["user_dashboard_inclusions"][id] = {};
        params["user_dashboard_inclusions"][id]["sort_order"] = ind + 1;

      });

      // Update in database
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url + $.param(params),
        complete(result) { 
          $('#dashboardModulesModal').attr('data-changed', 'true');
          $('#dashboardModulesModal .modal-content').find('.spinner-container').remove();
          $('#dashboardModulesModal .modal-body').css('opacity', 1)
        }
      });

    }
  });


}