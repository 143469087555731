import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';
import Swal from 'sweetalert2';

export default class AccountsResearchApiKeys {

  constructor() {}

  render() {

    // Password visibility
    PasswordVisibility();

    $('.show-key').click(function() {
      let keyId = $(this).attr('data-key-id');
      let $input = $('#' + keyId);      
      if ($input.attr('type') === "password") {
        $input.attr('type', 'text');
        let $title = $(this).attr('data-title');
        $title = $title.replace("Show", "Hide");
        $(this).attr('data-original-title', $title);
        $(this).find('i, svg').removeClass('fa-eye').addClass('fa-eye-slash');
      } else {
        $input.attr('type', 'password');
        let $title = $(this).attr('data-title');
        $title = $title.replace("Hide", "Show");
        $(this).attr('data-original-title', $title);
        $(this).find('i, svg').removeClass('fa-eye-slash').addClass('fa-eye');
      }
    })

    // Confirm and delete an item on click
    $('#rotateKeys').click(function(e) {

      // Set model and item ids
      let accountUrl = $(this).attr('data-account-url');
      let url = '/' + accountUrl + '/api/keys/rotate';

      // Validate
      return Swal.fire({
        title: "Are you sure?",
        text: "Rotating your keys cannot be undone. The old keys will immediately stop working.",
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        
        if (result.value) {

          // Unlink the account
          $.ajax({
            type: 'PATCH',
            url: url,
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success(result) { 
			        let accountUrl = $('#rotateKeys').attr('data-account-url');
			        let url = '/' + accountUrl + '/api/keys?notice=API keys rotated';
			        return Turbo.visit(url);
            },
            error(error) { 
			        let accountUrl = $('#rotateKeys').attr('data-account-url');
			        let url = '/' + accountUrl + '/api/keys?notice=There was a problem, please contact us for support';
			        return Turbo.visit(url);
            }
          });

        }

      });

    });

  }

}
