import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';

export default function(el) {
  let accountUrl = $('body').attr('data-account-url');

  // Funds and benchmarks
  filterTypeahead('#fund_search', 'funds', '/funds/search?country=all&', 42, 'logo');
  filterTypeahead('#fund_benchmark_search', 'funds', '/funds/search?country=all&', 42, 'logo');
  filterTypeahead('#portfolio_search', 'portfolios', '/' + accountUrl + '/portfolios/search?', 42, 'generic');

  // On selecting a fund 
  $('#fund_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {

    // Set hidden field value
    let fundId = suggestion.value;
    $('#fund_id').val(fundId);
    $('#portfolio_id').val('');
    $('#portfolio_search').typeahead('val', '');

    // Blur focus
    $('#fund_search').blur();

    // Enable save if both fund and benchmark selected
    let fundSelected = $('#fund_id').val().length > 0;
    let portSelected = $('#portfolio_id').val().length > 0;
    if ( (fundSelected || portSelected) && ($('#benchmark_fund_id').val().length > 0) ) {
      $('#createCalculatorDisabled').removeClass('d-block').addClass('d-none');
      $('#createCalculatorSubmit').removeClass('d-none').addClass('d-block');
    } else {
      $('#createCalculatorSubmit').removeClass('d-block').addClass('d-none');
      $('#createCalculatorDisabled').removeClass('d-none').addClass('d-block');        
    }

  })

  // On selecting a portfolio 
  $('#portfolio_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {

    // Set hidden field value
    let portfolioId = suggestion.value;
    $('#portfolio_id').val(portfolioId);
    $('#fund_id').val('');
    $('#fund_search').typeahead('val', '');

    // Blur focus
    $('#portfolio_search').blur();

    // Enable save if both fund and benchmark selected
    let fundSelected = $('#fund_id').val().length > 0;
    let portSelected = $('#portfolio_id').val().length > 0;
    if ( (fundSelected || portSelected) && ($('#benchmark_fund_id').val().length > 0) ) {
      $('#createCalculatorDisabled').removeClass('d-block').addClass('d-none');
      $('#createCalculatorSubmit').removeClass('d-none').addClass('d-block');
    } else {
      $('#createCalculatorSubmit').removeClass('d-block').addClass('d-none');
      $('#createCalculatorDisabled').removeClass('d-none').addClass('d-block');        
    }

  })

  // On selecting a fund as benchmark
  $('#fund_benchmark_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {

    // Set hidden field value
    let benchmarkId = suggestion.value;
    $('#benchmark_fund_id').val(benchmarkId);

    // Blur focus
    $('#fund_benchmark_search').blur();

    // Enable save if both fund and benchmark selected
    let fundSelected = $('#fund_id').val().length > 0;
    let portSelected = $('#portfolio_id').val().length > 0;
    if ( (fundSelected || portSelected) && ($('#benchmark_fund_id').val().length > 0) ) {
      $('#createCalculatorDisabled').removeClass('d-block').addClass('d-none');
      $('#createCalculatorSubmit').removeClass('d-none').addClass('d-block');
    } else {
      $('#createCalculatorSubmit').removeClass('d-block').addClass('d-none');
      $('#createCalculatorDisabled').removeClass('d-none').addClass('d-block');        
    }

  })

  // On clearing typeahead
  $('.clear-typeahead').click(function() {
    let t = $(this).closest('.form-group').find('.typeahead');
    t.typeahead('val', '');
    t.focus();
  })

  // Disable save on blur
  $('#portfolio_search, #fund_search').blur(function() {
    let portVal = $('#portfolio_search').val();
    let fundVal = $('#fund_search').val();
    if ((portVal.length === 0) && fundVal.length === 0) {
      $('#createCalculatorSubmit').prop('disabled', true);
    } else {
      $('#createCalculatorSubmit').prop('disabled', false);      
    }
  })


}