import FilePicker from 'src/javascripts/components/forms/FilePicker';

export default class RecommendationsActionPlansNewImport {

  constructor() {}

  render() {

    // Upload file
    FilePicker();

    // Update on click
    $('.submit-button').click(function(e) {
      e.preventDefault();
      let accountUrl = $(this).attr('data-account-url');
      let url = '/' + accountUrl + '/recommendations/action_plans/import';
      let formData = {}
      formData['file'] = {}
      formData['file']['key'] = $(this).attr('data-key');

      // Update local attribute
      return $.ajax({
        type: 'POST',
        url: url,
        dataType: "application/json",
        data: formData,
        complete(result) { 
          let url = window.location.href.split('?')[0];
          if (result.status === 200) {
            url = '/' + accountUrl + '/recommendations?notice=Your import has started. Note that it may take a minute to finish';
          } else {
            url = '/' + accountUrl + '/recommendations?notice=There was a problem, please contact us for support';
          }
          return Turbo.visit(url);
        },
      });


    })


  }

}