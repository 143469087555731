// Stripe main update card method
// https://stripe.com/docs/billing/subscriptions/payment#signup-2
import SuccessfulUpdateToast from 'src/javascripts/components/stripe/SuccessfulUpdateToast';
import ChangeLoadingState from 'src/javascripts/components/utilities/ChangeLoadingState';

export default function() {

  // Set accountUrl constant
  const accountUrl = $('body').attr('data-account-url');

  /* Post-payment helpers
  /* Show a success / error message when the card update is complete */
  var updateComplete = function(response) {
    ChangeLoadingState($('#submitUpdateCard'), false);
    // Reload page and show success message to user
    let url = window.location + '?notice=Successfully updated your card.';
    Turbo.visit(url);
  }

  var stripe;

  // Initiate StripeElements
  var stripeElements = function(publicKey) {
    stripe = Stripe(publicKey);
    var elements = stripe.elements();

    // Set basic style of input form to match app
    var style = {
      base: {
        fontSize: '16px',
        color: '#6c757d',
        fontFamily: 'Source Sans Pro, sans-serif',
        '::placeholder': {
          color: '#adb5bd'
        }
      }
    };

    // Create card element and listen for change as user types
    // Display error messages below form 
    var card = elements.create('card', { style: style });
    card.mount('#updateCardElement');

    // Element focus ring
    card.on('focus', function() {
      var el = document.getElementById('updateCardElement');
      el.classList.add('focused');
    });
    card.on('blur', function() {
      var el = document.getElementById('updateCardElement');
      el.classList.remove('focused');
    });

    // Show errors as user types in form
    card.addEventListener('change', ({error}) => {
      const displayError = document.getElementById('updateCardErrors');
      if (error) {
        displayError.className = 'with-error';
        displayError.textContent = error.message;
      } else {
        displayError.className = '';
        displayError.textContent = '';
      }
    });

    // Listen for submit, as long as button is not disabled
    document.querySelector('#submitUpdateCard').addEventListener('click', function(evt) {
      evt.preventDefault();
      // if not disabled, change load state and submit payment method, create customer
      ChangeLoadingState($('#submitUpdateCard'), true);
      // Initiate payment
      updatePaymentMethod(stripe, card);
    });
  };


  // Show error if card declined
  function showCardError(error) {
    ChangeLoadingState($('#submitUpdateCard'), false);
    // The card was declined (i.e. insufficient funds, card has expired, etc)
    var errorMsg = document.querySelector('#updateCardErrors');
    errorMsg.textContent = error.message;
    setTimeout(function() {
      errorMsg.className = '';
      errorMsg.textContent = '';
    }, 8000);
  }


  // Create a payment method and customer
  var updatePaymentMethod = function(stripe, card) {
    var cardholderEmail = document.querySelector('#updateCardEmail').value;
    stripe
      .createPaymentMethod('card', card, {
        billing_details: {
          email: cardholderEmail
        }
      })
      .then(function(result) {
        if (result.error) {
          showCardError(result.error);
        } else {
          updateCustomerPaymentMethod(result.paymentMethod.id, cardholderEmail);
        }
      });
  };


  // Update customer payment method with async call
  async function updateCustomerPaymentMethod(paymentMethod, cardholderEmail) {
    return fetch('/marketplace/' + accountUrl + '/stripe/payment_methods/update', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: cardholderEmail,
        payment_method: paymentMethod
      })
    })
      .then(response => {
        updateComplete(response);
      });
  }


  function getPublicKey() {
    return fetch('/marketplace/' + accountUrl + '/stripe-public-key', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(response) {
        stripeElements(response.publicKey);
      });
  }

  getPublicKey();


}