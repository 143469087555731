import Spinner from 'src/javascripts/components/utilities/Spinner';
import RecRequest from 'src/javascripts/components/RecRequest';
import ListCarousels from 'src/javascripts/components/lists/ListCarousels';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import AssessmentCausesChart from 'src/javascripts/components/charts/AssessmentCausesChart';

export default class AssessmentsResults {

  constructor() {}

  render() {

    // Get recommendation requests
    RecRequest();

    // Dashboard carousels
    ListCarousels();

    // Expand excerpted statements on click
    ExpandExcerpt();

    // Expand/minimize profile details
    ExpandContent();

    // Render chart if present
    if ( ($('#assessment_causes_chart').length !== 0) && (typeof $('#assessment_causes_chart').data('c3-chart') === 'undefined') ) {
      const chart = new AssessmentCausesChart( $('#assessment_causes_chart') );
      $.when( chart.render() );
    }

    // Get typical portfolio example if for a client
    if ( $('.assessment-vis-default').length > 0) {

      // Set params for loading metrics
      let accountUrl = $('.assessment-vis-default').attr('data-account-url');
      let assessmentId = $('.assessment-vis-default').attr('data-assessment-id');
      let backPath = $('.assessment-vis-default').attr('data-back-path');
      let url = '/' + accountUrl + '/portfolios/causes/assessment_vis_default/' + assessmentId;
      let params = {};
      params['back_path'] = backPath

      // Load assessment visuals
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });

      // Remove ajax-loaded resources before Turbo cache; otherwise loads twice on back-button
      $(document).on("turbo:before-cache", function() {
        $('.assessment-vis-default').empty();
      })

    }

    // Get example portfolio example if for a client
    if ($('#assessmentVisExample').length > 0) {

      // Set params for loading metrics
      let accountUrl = $('#assessmentVisExample').attr('data-account-url');
      let assessmentId = $('#assessmentVisExample').attr('data-assessment-id');
      let backPath = $('#assessmentVis').attr('data-back-path');
      let url = '/' + accountUrl + '/portfolios/causes/assessment_vis_example/' + assessmentId;
      let params = {};
      params['back_path'] = backPath

      // Load assessment visuals
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });

      // Remove ajax-loaded resources before Turbo cache; otherwise loads twice on back-button
      $(document).on("turbo:before-cache", function() {
        $('#assessmentVisExample').empty();
      })

    }

  }

}