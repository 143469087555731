import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class AccountsImpactCalculatorsPublic {

  constructor() {}

  render() {

	  // Format display numbers in form
	  NumberDisplay();

    // Prev, next section
    $('.prev-section, .next-section').click(function() {
      let target = $(this).attr('data-target-key');
      let targetCard = $('.calculator-card[data-section-key=' + target + ']');
      $(this).closest('.calculator-card, form').addClass('d-none').removeClass('d-flex');
      targetCard.removeClass('d-none').addClass('d-flex');
      targetCard.closest('form').removeClass('d-none').addClass('d-flex');
    })

    // Check for valid email
		function isValidEmail(email) {
		  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		  return emailRegex.test(email);
		}

    // Enable submit if all info provided
    $('.calc-required').keyup(function() {
			let emptyInputs = $(".calc-required").filter(function() {
			  return $(this).val() === "";
			});
    	let email = $("#calculator_submit_contact_email").val();
			if (emptyInputs.length > 0) {
				$('#disabledSubmitCalc').removeClass('d-none').addClass('d-block');
				$('#enabledSubmitCalc').removeClass('d-block').addClass('d-none');
    	} else if (!isValidEmail(email)) {
				$('#disabledSubmitCalc').removeClass('d-none').addClass('d-block');
				$('#enabledSubmitCalc').removeClass('d-block').addClass('d-none');
			} else {
				$('#disabledSubmitCalc').removeClass('d-block').addClass('d-none');
				$('#enabledSubmitCalc').removeClass('d-none').addClass('d-block');				
			}
    });

    // Toast if invalid email
    $('#calculator_submit_contact_email').blur(function() {
    	let email = $("#calculator_submit_contact_email").val();
    	if (!isValidEmail(email)) {
				$('#disabledSubmitCalc').removeClass('d-none').addClass('d-block');
				$('#enabledSubmitCalc').removeClass('d-block').addClass('d-none');
				ToastCustom('Invalid email', 'Please enter a valid email to continue');
			}
		});

    // Submit hypo investment
    $('#submitHypotheticalInvestment').click(function() {
      let target = $(this).attr('data-target-key');
      let value = $('#hypothetical_investment_amount').val();
      let url = $(this).attr('data-url');
    	url = url + "?key=" + target + "&value=" + value;
    	Turbo.visit(url);
    })

	  // Update initial investment hidden field on type of text field
	  $(".value-text").on("change paste keyup", function() {

	    // skip for arrow keys
	    if(event.which >= 37 && event.which <= 40) return;

	    // Update related numeric input (remove comma, return to value)
	    let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
	    let numericVal = parseFloat( $(this).val() );
	    if (Number.isNaN(numericVal)) {
	      numericVal = 1;
	      numericInput.val(numericVal);
	    } else if (numericVal < 1) {
	      numericVal = 100000;
	      numericInput.val(numericVal);
	      $(this).val(('100000'));
	      ToastCustom('Too small', 'Value must be greater than 0', 3000);
	    } else if (numericVal >= 1000000000000) {
	      numericVal = 100000;
	      numericInput.val(numericVal);
	      $(this).val(('100000'));
	      ToastCustom('Too large', 'Max value is $999,999,999,999', 3000);
	    } else {
	      numericInput.val(numericVal);
	    }

	  });


  }

}