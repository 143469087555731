export default function() {

  // Close assistant
  $('#AssistantContainer .Close').click(function() {
    $('#AssistantContainer').removeClass('open').addClass('closed');
  })

  // Expand assistant
  $('#AssistantContainer .Open').click(function() {
    $('#AssistantContainer').addClass('open').removeClass('closed');
    $('.AssistantForm').find('textarea').focus();
    $('#AssistantContent').scrollTop($('#AssistantContent')[0].scrollHeight);
  })

  // Search messages
  function delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  $('#search_messages').keyup(delay(function (e) {
    let accountUrl = $('body').attr('data-account-url');
    let threadId = $('#AssistantContainer').attr('data-thread-id');
    let url = '/' + accountUrl + '/assistants/threads/' + threadId + '/messages';
    let params = {};
    params['query'] = $(this).val();
    $.ajax({
      type: "GET",
      dataType: "script",
      timeout: 3000,
      url: url + '.js?' + $.param(params)
    });
  }, 500));

  // Scroll to bottom on page load
  $('#AssistantContent').scrollTop($('#AssistantContent')[0].scrollHeight);

  // If there's another fixed footer at bottom of page, adjust position of assistant
  let isFixed = $('#AssistantContainer.fixed');
  if (isFixed.length > 0) {
    let otherFixed = $(".form-footer, .fixed-footer").filter(function() {
      let fixed = $(this).css("position") === "fixed"; 
      let bottom = $(this).css("bottom") === "0px"; 
      return fixed && bottom;
    })
    
    if (typeof otherFixed !== 'undefined') {
      let otherFixedHeight = parseInt(otherFixed.css('height'));
      let newAssistantPosition = otherFixedHeight + 10;
      $('#AssistantContainer').css('bottom', newAssistantPosition + 'px');
    }
  }

}

