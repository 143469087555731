import Swal from 'sweetalert2';

export default function() {

  $('.cancel-subscription').click(function() {

    // Set model and item ids
    let accountUrl = $(this).attr('data-account-url');
    let freeTrialDays = $(this).attr('data-free-trial-days');
    let planId = $(this).attr('data-plan-id');
    let text = "Are you sure that you would like to cancel this subscription?"
    const cancelUrl = '/' + accountUrl + '/stripe/subscriptions?plan_id=' + planId;

    // Validate
    return Swal.fire({
      title: "Confirm cancel subscription",
      text: text,
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      
      if (result.value) {

        // Cancel the subscription
        $.ajax({
          type: 'DELETE',
          dataType: "application/json",
          url: cancelUrl,
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          complete(result) { 
            let notice;
            if (result.status === 200) {
              notice = 'Subscription cancelled';
            } else {
              notice = 'There was a problem, please contact us for support';
            }
            let reloadUrl = "/" + accountUrl + "/subscriptions/edit?notice=" + notice;
            return Turbo.visit(reloadUrl);
          },
        });

      }

    });

  });

}