import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import PortfolioModelItemsTable from 'src/javascripts/components/tables/PortfolioModelItemsTable';
import {genericDisplayTemplate, logoTemplate, noResultsTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import Swal from 'sweetalert2';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Set constants
  const accountUrl = $('#portfolioAssetsCard').attr('data-account-url');
  const portfolioId = $('#portfolioAssetsCard').attr('data-portfolio-id');
  const modelId = $('#portfolioAssetsCard').attr('data-model-id');
  const masterModelId = $('#portfolioAssetsCard').attr('data-master-model-id');
  const isReusable = $('#portfolioAssetsCard').attr('data-is-reusable');
  const updateReusableOk = $('#portfolioAssetsCard').attr('data-update-reusable-ok');

  // Set function for confirming leaving without saved changes
  const confirmLeave = function(e, href) {
    e.preventDefault();

    // Confirm and then continue with request
    return Swal.fire({
      title: "You have unsaved changes",
      text: "Are you sure you want to leave? You have unsaved changes that have not been applied.",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Continue anyway',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        Turbo.visit(href);
      }
    });

  }

  // Add spinner and opacity
  Spinner($('#portfolioAssetsCard'));
  $('#portfolio_model_items_body').css('opacity', 0.25);

  // Render assets table if present on page
  if ( $('#portfolio_model_items_table_wrapper').length === 0 ) {
    const modelItemsTable = new PortfolioModelItemsTable($('#portfolio_model_items_table'));
    $.when( modelItemsTable.render() ).then( status => modelItemsTable.setUp('portfolio_model_items_table') )
  }

  // Auto show the model name portfolio
  if ( $('#setModelInfo').attr('data-auto-show') === 'true' ) {
    $('#updateModelInfoModal').modal('show');
  }

  // On save add spinners, opacity
  $('.save-portfolio-model').click(function(e) {

    // Submit form if name present
    let nameField = $('#reusableModelNameInput')
    if (nameField.length > 0) {

      let name = nameField.val();
      if (name.length > 0) {

        // Submit form
        Spinner($('#portfolioAssetsCard'));
        $('#portfolio_model_items_body').css('opacity', 0.25);
        Spinner($('#researchResultsCard'));
        $('#researchResultsCard .card-body').css('opacity', 0.25);
        $(this).tooltip('hide');

      } else {

        e.preventDefault();
        ToastCustom('Name required', 'Please enter a name for the model', 3000);
        setTimeout(function() {
          nameField.focus();
        }, 500);
      }

    } else {

      // Not a reusable model, so don't require name
      Spinner($('#portfolioAssetsCard'));
      $('#portfolio_model_items_body').css('opacity', 0.25);
      Spinner($('#researchResultsCard'));
      $('#researchResultsCard .card-body').css('opacity', 0.25);
      $(this).tooltip('hide');

    }

  });

  // Confirm discard changes
  $('.discard-changes').click(function(e) {
    e.preventDefault();
    let updateUrl = '/' + accountUrl + '/portfolios/models/' + masterModelId + '/discard_changes'
    let reloadUrl = $(this).attr('data-reload-url');

    // Confirm and then continue with request
    return Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to discard the changes to your model?",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        $.ajax({
          type: 'PATCH',
          url: updateUrl,
          dataType: 'application/json',
          complete(result) { 
            Turbo.visit(reloadUrl);
          },
        });
      }
    });
  });

  // On update benchmark filter
  $('.benchmark-filter').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    $('#reusableModelBenchmarkInput').val(suggestion.fund_id);
  });

  // On selecting a fund as benchmark
  $('.clear-typeahead').click(function() {
    let t = $(this).closest('.form-group').find('.typeahead');
    t.typeahead('val', '');
    t.focus();
  })

  // If unsaved changes, confirm before leaving 
  $('a:not(.save-portfolio-model):not(.discard-changes):not(.uc-ok)').click(function(e) {
    let uc = $('.save-portfolio-model').attr('data-unsaved-changes');
    let href = $(this).attr('href');
    if ( typeof uc !== 'undefined' && uc !== false && href !== '#' ) {
      confirmLeave(e, href);
    }
  });

  // Update name on input
  $('.update-model-info').off().click(function(e) {
    e.preventDefault();
    let nameVal = $('#reusableModelNameInput').val();
    let descVal = $('#reusableModelDescInput').val();
    let benchmarkId = $('#reusableModelBenchmarkInput').val();
    let accountVal = $('#value_of_account_modal').val();
    let params = {};
    params['portfolio_model'] = {};
    params['portfolio_model']['name'] = nameVal
    params['portfolio_model']['description'] = descVal
    params['portfolio_model']['benchmark_fund_id'] = benchmarkId;
    params['portfolio_model']['value'] = accountVal
    let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/update_attrs?' + $.param(params);
    if (nameVal === '') {
      ToastCustom('Name required', 'Please enter a name for the model', 3000);
    } else if ((accountVal === '') || (accountVal === 0)) {
      ToastCustom('Value required', 'Please enter an account value greater than 0', 3000);
    } else {
      $.ajax({
        type: 'PATCH',
        url: url,
        dataType: 'application/json',
        complete(result) { 
          // Close modal
          $('#updateModelInfoModal').modal('hide');
          // Reload
          let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/edit';
          params = {};
          return Turbo.visit(url + '?' + $.param(params));
        },
      })
    }
  });

  // Prevent default for confirm update modal
  $('#confirmUpdatePortfoliosBtn').click(function(ev) {
    ev.preventDefault();
  })

  // Init portfolio models bloodhound
  const modelsBloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/' + accountUrl + '/portfolios/models/search?reusable=true&query=%QUERY',
      wildcard: "%QUERY"
    }
  });
  modelsBloodhound.initialize();

  // Initiate apply models typeahead
  $('.apply-model-typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: 'models',
    display: 'name',
    limit: 54,
    source: modelsBloodhound.ttAdapter(),
    templates: {
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return genericDisplayTemplate(el.name);
        }
      }
    },
  });

  // Import assets
  $('.import-assets').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // If model is reusable and portfolio is present, double check that
    // user wants to update underlying model
    let uc = $('.save-portfolio-model').attr('data-unsaved-changes');
    let url = "/" + accountUrl + "/portfolios/models/" + modelId + "/imports/new";
    let params = {};
    let display = $(this).attr('data-display');
    let back_path = $(this).attr("data-back-path");
    let assets_scope = $(this).attr("data-assets-scope");
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    if (typeof display !== 'undefined') {params['display'] = display;}
    if (typeof back_path !== 'undefined') {params['back_path'] = back_path;}
    if (typeof assets_scope !== 'undefined') {params['assets_scope'] = assets_scope;}
    let href = url + '?' + $.param(params);
    if ( typeof uc !== 'undefined' && uc !== false ) {
      confirmLeave(ev, href);
    } else {
      Turbo.visit(href);
    }
  });

  // Reblance weights to 100%
  $('.rebalance-portfolio-model').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // If model is reusable and portfolio is present, double check that
    // user wants to update underlying model
    // Hide tooltip
    $(this).tooltip('hide');

    // Add spinner and opacity to table
    Spinner($('#portfolioAssetsCard'));
    $('#portfolio_model_items_body').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['portfolio_model_filter'] = {}
    params['portfolio_model_filter']['model_id'] = modelId;
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    params['scope'] = $(this).attr('data-scope');
    let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/rebalance?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'PATCH',
      url: url,
      dataType: 'script',
      complete(result) { 
        // Reload table data
        $('#portfolio_model_items_table').DataTable().ajax.reload();

        // Re-enable typeaheads and add filters
        $('.model-interaction').removeClass('disabled');

        // Set unsaved changes to true
        $('.save-portfolio-model').attr('data-unsaved-changes', true);
      },
    });
  });

  // Don't submit forms when hit enter on search bars
  $('.card-table-search').keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault();
      return false;
    }
  });

  // Apply an existing model on typeahead select
  $('.apply-model-typeahead').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Add spinner and opacity to table
    Spinner($('#portfolioAssetsCard'));
    $('#portfolio_model_items_body').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['portfolio_id'] = portfolioId;
    params['existing_model_id'] = modelId;
    let url = '/' + accountUrl + '/portfolios/models/' + suggestion.value + '/dup_existing?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'PATCH',
      url: url,
      dataType: 'application/json',
      complete(result) { 
        // Reload page
        Turbo.visit("/" + accountUrl + "/portfolios/models/" + masterModelId + "/edit?portfolio_id=" + portfolioId + "&uc=true&notice=Added " + suggestion.name + ". Click 'Save' to save the model");
      },
    });

  });

  // Empty typeaheads on select
  $('.research-filters-typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $(this).typeahead('val', '');
  });

  $('.research-filters-typeahead').blur(function() {
    $(this).typeahead('val', '');
  });

  // Start import status poll if importing
  let importPoll = () => {

    // Get generating message on page
    let isGenerating = $('.generating-message');

    // Only continue if status indicator present (if should poll for recs is true)
    if (isGenerating.length !== 0) {

      // Set Api url to get data
      let accountUrl = isGenerating.attr('data-account-url');
      let portfolioId = isGenerating.attr('data-portfolio-id');
      let modelId = isGenerating.attr('data-model-id');
      let params = {};
      if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
      let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/check_import_status.js?' + $.param(params);

      // Execute ajax request (using js erb template to render content so can control profile styling more easily)
      // Must specify '.js' otherwise processes as JSON
      $.ajax({
        type: "POST",
        dataType: "script",
        timeout: 3000,
        url: url,
        complete() {
          setTimeout(function() { importPoll() }, 4000);
        }
      });
    }
  };

  // Poll server for status of mapped items, if generating message present
  if ($('.generating-message').length !== 0) { importPoll(); }

}